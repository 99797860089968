<template>
  <div>
    <router-link
      v-slot="{ navigate, isActive, isExactActive }"
      :to="{ name: menuItem.name }"
      custom
    >
      <v-list-item
        :class="{
          'router-link-active': isActive,
          'router-link-exact-active': isExactActive,
          'menu-item': true,
        }"
        @click="handleClick(navigate)"
      >
        <slot v-if="menuItem.icon" name="append">
          <v-icon>{{ menuItem.icon }}</v-icon>
        </slot>
        <v-list-item-title>{{ menuItemTitle }}</v-list-item-title>
      </v-list-item>
    </router-link>

    <v-list v-if="hasChildren" class="ml-4">
      <menu-item
        v-for="(child, index) in menuItem.children"
        :key="index"
        :menu-item="child"
      ></menu-item>
    </v-list>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import { MenuItem } from '../composable/useMenuItems';

interface MenuItemProps {
  menuItem: {
    // The `title` property can be either a string or a function that takes no arguments and returns a string
    title: string | (() => string);
    path: string;
    icon: string;
    children: MenuItem[];
  };
}

export default defineComponent({
  name: 'MenuItem',
  props: {
    menuItem: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const hasChildren = computed(() => !!props.menuItem.children.length);
    const isOpen = ref(false);
    const menuItemTitle = computed(() => {
      if (typeof props.menuItem.title === 'function') {
        return props.menuItem.title();
      }
      return props.menuItem.title;
    });

    function toggle() {
      if (hasChildren.value) {
        isOpen.value = !isOpen.value;
      }
    }

    function handleClick(navigateFunction: () => void) {
      navigateFunction();
      emit('close');
    }

    return { hasChildren, isOpen, toggle, menuItemTitle, handleClick };
  },
});
</script>
