<template>
  <v-text-field
    data-cy="password-input"
    label="Password"
    name="password"
    prepend-icon="mdi-lock"
    :type="showPassword ? 'text' : 'password'"
    :rules="[rules.required]"
    :model-value="modelValue"
    variant="filled"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #append-inner>
      <v-icon
        data-cy="show-hide-pwd-button"
        :aria-label="appendIconLabel"
        @click="showPassword = !showPassword"
        >{{ appendIcon }}</v-icon
      >
    </template>
  </v-text-field>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * A text field to receive a password
 * Includes an 'append-icon' setup to switch the input from text to
 * password type, to show the password in plain text on demand
 */
export default defineComponent({
  name: 'BaseInputPassword',
  props: {
    /**
     * The password value (if it exists already)
     */
    modelValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      /**
       * Whether to show the password in plain text
       */
      showPassword: false,
      /**
       * Validation rules
       */
      rules: {
        required: (value: any) => !!value || 'Required.',
      },
    };
  },
  computed: {
    type(): string {
      return this.showPassword ? 'text' : 'password';
    },
    appendIcon(): string {
      return this.showPassword ? 'mdi-eye' : 'mdi-eye-off';
    },
    appendIconLabel(): string {
      return this.showPassword ? 'Hide password' : 'Show password';
    },
  },
});
</script>

<style scoped>

</style>
