<template>
    <slot name="default">
      <v-card v-if="dialog">
        <slot name="title">
          <v-card-title class="headline">{{ getDialogTitle(dialog.error) }}</v-card-title>
        </slot>
          <v-card-text>
            <RecordDownloadAlert v-if="formService" :form-service="formService" class="mb-2"></RecordDownloadAlert>
            <slot name="text">{{ dialog?.error?.message}}</slot>
          </v-card-text>
        <slot name="actions">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" variant="text" @click="emit('dismissDialog')">Close</v-btn>
            <v-btn v-if="dialog.callback" color="blue darken-1" variant="text" @click="emit('executeCallback')">{{ dialog.secondaryActionText }}</v-btn>
          </v-card-actions>
        </slot>
      </v-card>
    </slot>
</template>

<script setup lang="ts">
import {ErrorDialogEmits, ErrorDialogProps} from "@/common/types";
import {useDialog} from "@/composable/useDialog";
import {computed} from "vue";
import RecordDownloadAlert from "./RecordDownloadAlert.vue";
import {useTranslationNamespace} from "@/composable/useI18n";
import {I18nNamespace} from "@/i18n";

const props = defineProps<ErrorDialogProps>()
const emit = defineEmits<ErrorDialogEmits>()

const { dialogStore, getDialogTitle } = useDialog();
const formService = computed(() => dialogStore.context?.formService);
</script>
