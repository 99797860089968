<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    style="enable-background: new 0 0 200 130.2; zoom: 1"
    xml:space="preserve"
    viewBox="111.53 0.3 65.67 95.2"
  >
    <g>
      <polygon
        class="st0"
        points="111.527527,95.5 111.527527,66.800003 135.127518,59 176.927521,73.300003 "
      ></polygon>
      <polygon
        class="st1"
        points="153.600006,36.799999 111.800003,51.200001 135.399994,59 177.199997,44.599998 "
      ></polygon>
      <polygon
        class="st2"
        points="135.399994,59 177.199997,44.599998 177.199997,73.300003 "
      ></polygon>
      <polygon
        class="st3"
        points="177.2,0.3 177.2,29 153.6,36.8 111.8,22.5"
      ></polygon>
      <polygon class="st4" points="153.6,36.8 111.8,51.2 111.8,22.5"></polygon>
      <g>
        <g>
          <g><g></g></g>
        </g>
      </g>
    </g>
  </svg>
</template>

<style type="text/css">
.st0 {
  fill: #3eb1c8;
}
.st1 {
  fill: #d8d2c4;
}
.st2 {
  fill: #4f5858;
}
.st3 {
  fill: #ffc72c;
}
.st4 {
  fill: #ef3340;
}
</style>
