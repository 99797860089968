import { ref } from 'vue';
import { ThemeDefinition } from 'vuetify';
import { THEME_NAME_HIGH_CONTRAST, THEME_NAME_DEFAULT } from '@/constants/themes'

export const COLOURS = Object.freeze({
  crossref_dark_blue: '#017698',
  crossref_dark_red: '#a6192e',
  crossref_red: '#ef3340',
  crossref_blue: '#3eb1c8',
  crossref_tan: '#d8d2c4',
  crossref_yellow: '#ffc72c',
  crossref_grey: '#4f5858',
  crossref_green: '#00ab84',
  crossref_dark_green: '#006740',
  crossref_dark_beige: '#a39382',
  crossref_orange: '#fd8332',
  crossref_dark_grey: '#4f5858',
  crossref_light_grey: '#d8d2c4',
  crossref_dark_yellow: '#ffa300',
  generic_light_grey: '#f3f3f3',
  generic_dark_teal: '#328ea0',
  generic_dark_purple: 'C90B58FF',
  generic_red: '#b9203c',
  generic_dark_red: '#c00d18',
  generic_white: '#FFFFFF',
})

const themes = ref<Record<string, ThemeDefinition>>({
  [THEME_NAME_DEFAULT]: {
    dark: false,
    colors: {
      primary: '#3eb1c8',
      secondary: '#4f5858',
      accent: '#3eb1c8',
      error: '#b9203c',
      info: '#2972fa',
      success: '#00ab84',
      warning: '#f4511e',
      background: '#f1f1f1',
      crossref_red: '#ef3340',
      crossref_blue: '#3eb1c8',
      crossref_tan: '#d8d2c4',
      crossref_yellow: '#ffc72c',
      crossref_grey: '#4f5858',
      toolbar: '#4f5858',
    },
  },
  [THEME_NAME_HIGH_CONTRAST]: {
    dark: false,
    colors: {
      anchor: COLOURS.generic_dark_teal,
      primary: COLOURS.crossref_dark_blue,
      secondary: COLOURS.crossref_yellow,
      error: COLOURS.generic_dark_red,
      info: COLOURS.generic_dark_purple,
      success: COLOURS.crossref_green,
      warning: COLOURS.crossref_yellow,
      background: COLOURS.generic_light_grey,
      surface: COLOURS.generic_white,
      crossref_red: '#ef3340',
      crossref_dark_grey: '#4f5858',
      crossref_yellow: '#ffc72c',
      crossref_blue: '#3eb1c8',
      crossref_green: '#00ab84',
      crossref_lightgrey: '#d8d2c4',
      crossref_dark_yellow: '#ffa300',
      crossref_dark_red: '#a6192e',
      crossref_dark_blue: 'rgb(1, 118, 152)',
      crossref_darkbeige: '#a39382',
      crossref_orange: '#fd8332',
      crossref_tan: '#d8d2c4',
      crossref_grey: '#4f5858',
      generic_dark_purple: COLOURS.generic_dark_purple,
      generic_lightgrey: '#f3f3f3',
      toolbar: '#004b5f',
    },
  },
});
export type ThemeName = keyof typeof themes.value;

const defaultTheme = ref<ThemeName>(THEME_NAME_HIGH_CONTRAST);

const currentTheme = ref<ThemeName>(defaultTheme.value);

const changeTheme = (themeName: ThemeName) => {
  if (themes.value[themeName]) {
    currentTheme.value = themeName;
  }
};

export default function useTheme() {
  return { currentTheme, changeTheme, themes, defaultTheme };
}
