import { Ref, ref } from 'vue';

interface LoadingState {
  color: Ref<string>;
  isLoading: Ref<boolean>;
  startLoading: () => void;
  stopLoading: () => void;
}

const isLoading = ref(false);
const loading = ref('primary');
const colors = ['crossref_blue', 'error', 'crossref_yellow'];
let colorIndex = -1;

setInterval(() => {
  colorIndex = (colorIndex + 1) % colors.length;
  loading.value = colors[colorIndex];
}, 1500);

const startLoading = () => {
  isLoading.value = true;
};
const stopLoading = () => {
  isLoading.value = false;
};

export const useLoadingColors = (): LoadingState => {
  return {
    color: loading,
    isLoading: isLoading,
    startLoading,
    stopLoading,
  };
};
