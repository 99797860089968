<template>
  <v-app-bar v-if="show" density="compact" color="crossref_dark_grey" elevation="0">
    <div class="d-flex justify-center align-center" style="width: 100%;">
      <v-btn icon>
        <v-icon>mdi-information</v-icon>
        <v-dialog v-model="dialog"  activator="parent" :max-width="dialogMaxWidth" :fullscreen="xs">
          <v-card>
            <v-card-title class="text-h5">{{ t('info_connectedEnvironmentDialog_title') }}</v-card-title>
            <v-card-text>
              {{ descriptionMsg }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="dialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-btn>
      <p>{{ msg }}</p>
    </div>
  </v-app-bar>
</template>


<script lang="ts" setup>
  import {env} from "@/env";
  import {computed, ComputedRef, ref} from "vue";
  import {useTranslation} from "@/composable/useI18n";
  import { useDisplay } from 'vuetify'

  // Destructure only the keys you want to use
  const { xs } = useDisplay()
  const t = useTranslation()
  const dialog = ref(false)
  const dialogMaxWidth = computed(() => {
    return xs.value ? '100%' : '75%'
  })
  const isStaging: ComputedRef<boolean> = computed( () => {
    return (env().keymakerAPIBaseURL === 'https://idm.staging.crossref.org/auth'
    && env().xmlDepositApiHost === 'https://test.crossref.org')
  })
  const isDevelopment: ComputedRef<boolean> = computed(  () => {
    return env().keymakerAPIBaseURL === 'https://127.0.0.1:8443/auth'
  })

  const show = computed(() => isStaging || isDevelopment)

  const msg = computed(() => {
    if (isStaging.value) return t('info_connectedEnvironmentBanner_msg', { env: 'staging'})
    if (isDevelopment.value) return t('info_connectedEnvironmentBanner_msg', { env: 'dev'})
  })

  const descriptionMsg = computed(() => {
    return t('info_connectedEnvironmentDialog_desc', { depositHost: env().xmlDepositApiHost, keymanangerHost: env().keymakerAPIBaseURL })
  })

</script>
