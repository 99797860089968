<template>
  <v-menu>
    <template #activator="{ props }">
      <v-btn
        color="primary"
        variant="outlined"
        data-cy="language-menu__button"
        class="mx-2"
        v-bind="props"
      >
        <v-icon light start>mdi-translate</v-icon>{{ locale }}</v-btn
      >
    </template>
    <v-list>
      <v-list-item
        v-for="item in locales"
        :key="item.title"
        :data-cy="listItemName(item)"
        :data-locale="item.locale"
        @click="changeLocale(item.locale)"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
/**
 * A drop-down menu presenting the available language choices
 */
import { defineComponent } from 'vue';
import { SupportedLocales, getEnabledLocales, Locale } from '@/i18n';
import { useLocale } from '@/composable/useI18n';
import { useAppService } from '@/statemachines/app.machine';

const { setLocale, locale } = useLocale();

export default defineComponent({
  name: 'LanguageMenu',
  setup() {
    const appService = useAppService();

    return { appService, locale };
  },
  data: () => ({
    /**
     * The available locales
     */
    locales: getEnabledLocales(),
  }),
  methods: {
    /**
     *
     * @param v The locale to switch to
     */
    changeLocale: function (v: SupportedLocales) {
      setLocale(v)
        .then(() => {
          // this.$vuetify.lang.current = v; // now handled in setLocale()
          this.appService.send({ type: 'UPDATE_LOCALE', locale: v });
        })
        .catch((error) => {
          console.error('Error when trying to change locale', error);
        });
    },
    listItemName: function (item: Locale) {
      return `language-menu__list-item--${item.locale}`;
    },
  },
});
</script>

<style scoped>

</style>
