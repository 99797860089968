<template>
  <v-card-title>
    <slot>Crossref Login</slot>
  </v-card-title>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * Renders the default crossref login box title
 */
export default defineComponent({
  name: 'LoginBoxCardTitle',
});
</script>

<style scoped>

</style>
