import { reactive } from 'vue';
import { AppService } from './statemachines/app.machine';

export interface Store {
  // FIXME: use the generated types for the Xstate machine. Currently those returned from xstate/vue2 cause an error.
  // Maybe improved in xstate-vue@3
  appService: AppService | undefined;
}

export const store: Store = reactive({
  appService: undefined,
});
