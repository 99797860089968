<template>
  <ErrorDialogBase :dialog="dialog" @dismiss-dialog="emit('dismissDialog')" @execute-callback="emit('executeCallback')">
    <template #title><v-card-title>{{ t('an-error-occurred') }}</v-card-title></template>
    <template #text>
        <TranslationComponent :translation="t(messageKey)">
        </TranslationComponent>
    </template>
    <template #actions>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="emit('dismissDialog')">{{ t('button-close') }}</v-btn>
        <v-btn color="primary" @click="emit('executeCallback')">{{ t('button-try-again') }}</v-btn>
      </v-card-actions>
    </template>
  </ErrorDialogBase>
</template>

<script setup lang="ts">
import {ErrorDialogEmits, ErrorDialogProps} from "@/common/types";
import {useDialog} from "@/composable/useDialog";
import ErrorDialogBase from "@/components/ErrorDialogBase.vue";
import {useTranslationNamespace} from "@/composable/useI18n";
import {I18nNamespace} from "@/i18n";
import {URL_DOCS_RECORDS_TROUBLESHOOTING_SUBMISSIONS, URL_MAILTO_SUPPORT, URL_STATUS_PAGE} from "@/constants/urls";
import {EMAIL_SUPPORT} from "@/constants/contact";
import {TranslationComponent} from "i18next-vue";
import AppLink from "@/components/AppLink.vue";
import {computed} from "vue";

const props = defineProps<ErrorDialogProps>()
const emit = defineEmits<ErrorDialogEmits>()

const t = useTranslationNamespace(I18nNamespace.ContentRegistration);
const messageKey = 'deposit_submission_error_not_authenticated';

const message = computed(() =>
  props.dialog?.error?.message);

const { getDialogTitle } = useDialog();
</script>

<style scoped>
.error-message {
  margin-top: 16px;
  padding: 12px;
  background-color: #343a40; /* Dark background for better contrast */
  border: 1px solid #495057; /* Slightly lighter border */
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre-wrap; /* Preserves whitespace formatting */
  color: #f8f9fa; /* Light text color for contrast */
  overflow-x: auto; /* Allows horizontal scrolling if the message is too wide */
}
</style>
