<script setup lang="ts">
import {FormDataService} from "@/statemachines/form-data.machine";
import {TranslationComponent} from "i18next-vue";
import {useTranslationNamespace} from "@/composable/useI18n";
import {I18nNamespace} from "@/i18n";
import RecordDownloadBtn from "./RecordDownloadBtn.vue";

const _props = defineProps<{
  formService: FormDataService
}>();

const t = useTranslationNamespace(I18nNamespace.ContentRegistration);
</script>

<template>
  <v-alert v-if="formService" variant=flat color="">
    <TranslationComponent :translation="t('records-download-your-record-for-later')">
      <template #download-action>
        {{ t('action-download') }}
      </template>
    </TranslationComponent>
    <template #append>
      <RecordDownloadBtn :form-service="formService"/>
    </template>
  </v-alert>
</template>
