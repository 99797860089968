<template>
  <div>
    <v-switch
      :model-value="isHighContrast"
      color="primary"
      :label="label"
      @update:model-value="onToggleHighContrast"
    ></v-switch>
  </div>
</template>

<script setup lang="ts">
import useFormTheme from '@/composable/useFormTheme';
import { useTranslation } from '@/composable/useI18n';
import useTheme from '@/composable/useTheme';
import { computed } from 'vue';

const t = useTranslation();
const label = t('accessibility_high_contrast_theme');
const { currentTheme, changeTheme } = useTheme();
const { changeFormTheme } = useFormTheme();

const isHighContrast = computed(() => currentTheme.value === 'highcontrast');

const onToggleHighContrast = () => {
  const newTheme = isHighContrast.value ? 'default' : 'highcontrast';
  changeTheme(newTheme);
  changeFormTheme(newTheme);
};
</script>

<style scoped>

</style>
