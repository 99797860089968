import { env } from '@/env';
import { SUPPORTED_LOCALES } from '@/env';
import ISO6391 from 'iso-639-1';

export type SupportedLocales = (typeof SUPPORTED_LOCALES)[number];
export const defaultLocale: SupportedLocales = 'en';

export type Locale = {
  locale: SupportedLocales;
  title: string;
};

export const getEnabledLocales = (): Locale[] =>
  env().localesEnabled.map((localeCode) => ({
    locale: localeCode,
    title: ISO6391.getNativeName(localeCode),
  }));

export enum I18nNamespace {
  App = 'app',
  ContentRegistration = 'content-registration-grant',
  FluentDemo = 'fluent-demo',
  KeyManager = 'keys',

}
export const I18N_FALLBACK_NAMESPACE: I18nNamespace = I18nNamespace.App;

export const regionNames = {
  en: new Intl.DisplayNames(['en'], { type: 'region', fallback: 'none' }),
  de: new Intl.DisplayNames(['de'], { type: 'region', fallback: 'none' }),
  es: new Intl.DisplayNames(['es'], { type: 'region', fallback: 'none' }),
  fr: new Intl.DisplayNames(['fr'], { type: 'region', fallback: 'none' }),
};

export const getRegionName = (
  locale: string,
  region: string
): string | undefined => {
  switch (locale) {
    case 'de':
      return safeGetRegion(regionNames.de, region);
    case 'es':
      return safeGetRegion(regionNames.es, region);
    case 'fr':
      return safeGetRegion(regionNames.fr, region);
    case 'en':
    default:
      return safeGetRegion(regionNames.en, region);
  }
};

const safeGetRegion = (
  displayNames: Intl.DisplayNames,
  region: string
): string | undefined => {
  try {
    return displayNames.of(region);
  } catch (error) {
    // an error is thrown in case the region code is invalid
  }
  return undefined;
};
