import {ContentRegistrationType, XMLGenerationStrategy} from "@/common/types";
import jsonata from "jsonata";
import {create} from "xmlbuilder2";

const jsonataExpr = jsonata(
  `
  {
    "doi_batch": {
        "@xmlns": "http://www.crossref.org/grant_id/0.1.1",
        "@xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
        "@xsi:schemaLocation": "http://www.crossref.org/grant_id/0.1.1 https://www.crossref.org/schemas/grant_id0.1.1.xsd",
        "@version": "0.1.1",
        "head": {
          "doi_batch_id": "batch_000001",
          "timestamp": $millis(),
          "depositor": {
            "depositor_name": depositor.depositor_name,
            "email_address": depositor.email_address
          },
          "registrant": "Crossref"
        },
        "body": {
          "grant": [grants.{
            "project": project.{
              "project-title": \`project-title\`.{
                "@xml:lang": lang,
                "#text": \`title-text\`
              },
              "investigators": investigators ? investigators{
                "person": $.{
                  "@role": role,
                  "givenName": givenName,
                  "familyName": familyName,
                  "alternateName": alternateName,
                  "affiliation": affiliation.{
                    "institution": {
                      "@country": country,
                      "#text": institution
                    },
                    "ROR": ror
                  },
                  "ORCID": ORCID
                }
              },
              "description": description.{
                "@xml:lang": lang,
                "#text": \`description-text\`
              },
              "award_amount": award_amount.amount ? award_amount{
                "#text": amount,
                "@currency": currency
              },
              "funding": funding.{
                "@funding-percentage": 100,
                "@funding-type": \`funding-type\`,
                "funder-name": {
                  "#text": \`funder-name\`
                },
                "funder-id": {
                  "#text": \`funder-id\`
                },
                "funding-scheme": {
                  "#text": \`funding-scheme\`
                }
              }
            },
            "award-number": \`award-number\`{
              "#text": $
            },
            "award-start-date": \`award-start-date\` ? \`award-start-date\`{
              "#text": $
            },
            "doi_data": doi_data.{
              "doi":  doi,
              "resource": resource
            }
          }]
        }
    }
  }
  `
);

/**
 * Strategy implementation for generating XML specifically for grant content types.
 * Utilizes a JSONata expression to transform grant-related data into an JSON structure to that required by the xmlbuilder2 library.
 */
export class GrantXmlRenderingStrategy implements XMLGenerationStrategy {
  async generateXML(
    recordType: ContentRegistrationType,
    data: unknown,
    prettyPrint = false
  ): Promise<string> {
    const result = jsonataExpr.evaluate(data); // Assuming jsonataExpr is defined elsewhere
    const doc = create({ encoding: 'UTF-8' }, result);
    return doc.end({ prettyPrint });
  }
}
