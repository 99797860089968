<template>
  <div>
    <v-alert type="success" data-cy="alert-success">{{ message }}</v-alert>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * A Vuetify alert set to type "success"
 */
export default defineComponent({
  name: 'AlertSuccess',
  props: {
    /**
     * The message to display
     */
    message: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="sass">
//
</style>
