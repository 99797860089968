<template>
  <v-text-field
    data-cy="username-input"
    label="Username"
    :rules="[rules.required, rules.minLength]"
    name="username"
    :disabled="disabled"
    prepend-icon="mdi-account"
    type="text"
    :model-value="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
  </v-text-field>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * A text field to receive a username
 */
export default defineComponent({
  name: 'BaseInputUsername',
  props: {
    /**
     * The username value (if it exists already)
     */
    modelValue: {
      type: String,
      required: true,
    },
    /**
     * Whether the input control is disabled or allows input
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
       * Validation rules
       */
      rules: {
        required: (value: any) => !!value || 'Required.',
        minLength: (value: string | undefined) =>
          (value && value.length >= 3) ||
          'Username must be 3 or more characters',
      },
    };
  },
});
</script>

<style scoped>

</style>
