<template>
  <v-btn variant="text" color="secondary" @click.prevent="onClick">
    Back
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * Renders the 'back' button
 */
export default defineComponent({
  name: 'LoginBoxActionButtonBack',
  methods: {
    onClick() {
      this.$emit('goBack');
    },
  },
});
</script>

<style scoped>

</style>
