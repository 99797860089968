import { ref, computed, Ref, defineComponent } from 'vue';

// Default button texts
const defaultConfirmButtonText = ref('button-ok');
const defaultCancelButtonText = ref('button_cancel_label');

// Dialog state
export const dialog = ref(false);
export const type = ref<'confirm' | 'input' | 'component'>('confirm');
export const inputType = ref<'free' | 'confirmation'>('free');
export const title = ref('');
export const subTitle = ref('');
export const message = ref('');
export const textValue = ref('');
export const confirmationText = ref('');
export const warningMessage = ref('');
export const confirmButtonText = ref('');
export const cancelButtonText = ref('');
export const showCancelButton = ref(true);
export const component: Ref<any> = ref(null);
export const componentProps: Ref<Record<string, any>> = ref({});
export const componentOnly: Ref<boolean> = ref(false);
export const actionsComponent: Ref<any> = ref(null);
export const actionsComponentProps = ref<any>({});
export const hideDefaultActions = ref<boolean>(false);
export const width = ref(500);
export const persistent = ref(false);

// Type for dialog request options and Promise resolve/reject
type DialogRequest = {
  options: {
    type?: 'confirm' | 'input' | 'component';
    inputType?: 'free' | 'confirmation';
    title?: string;
    subTitle?: string;
    message?: string;
    textValue?: string;
    confirmationText?: string;
    warningMessage?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    showCancelButton?: boolean;
    component?: any;
    componentProps?: Record<string, any>;
    componentOnly?: boolean;
    actionsComponent?: any;
    actionsComponentProps?: Record<string, any>;
    hideDefaultActions?: boolean;
    width?: number;
    persistent?: boolean;
  };
  resolve: (value: boolean | string) => void;
  reject: (reason: boolean | null | string) => void;
};

// Queue to handle multiple dialog requests
const dialogQueue: DialogRequest[] = [];

// Function to process the dialog queue
async function processQueue() {
  // If the queue is empty or a dialog is already open, do nothing
  if (dialogQueue.length === 0 || dialog.value) {
    return;
  }

  // Retrieve the first request from the queue
  const request = dialogQueue[0];
  const { options, resolve, reject } = request;

  // Set dialog state based on request options
  type.value = options.type || 'confirm';
  title.value = options.title || '';
  subTitle.value = options.subTitle || '';
  message.value = options.message || '';
  textValue.value = options.textValue || '';
  confirmationText.value = options.confirmationText || '';
  inputType.value = options.inputType || 'free';
  warningMessage.value = options.warningMessage || '';
  confirmButtonText.value =
    options.confirmButtonText || defaultConfirmButtonText.value;
  cancelButtonText.value =
    options.cancelButtonText || defaultCancelButtonText.value;
  showCancelButton.value = options.showCancelButton !== false;
  component.value = options.component
    ? defineComponent(options.component)
    : null;
  componentProps.value = options.componentProps || {};
  componentOnly.value = options.componentOnly || false;
  actionsComponent.value = options.actionsComponent
    ? defineComponent(options.actionsComponent)
    : null;
  actionsComponentProps.value = options.actionsComponentProps || {};
  hideDefaultActions.value = options.hideDefaultActions || false;
  width.value = options.width || width.value;
  persistent.value = options.persistent || persistent.value;

  // Open the dialog
  dialog.value = true;

  try {
    // Wait for the dialog to resolve or reject
    const result = await new Promise<boolean | string>((res, rej) => {
      request.resolve = res;
      request.reject = rej;
    });
    resolve(result);
  } catch (error) {
    reject(error as boolean | null | string);
  } finally {
    // Close the dialog and process the next request in the queue
    dialog.value = false;
    dialogQueue.shift();
    processQueue();
  }
}

// Function to open a new dialog with the given options
export function open(options: DialogRequest['options']) {
  return new Promise<boolean | string>((resolve, reject) => {
    dialogQueue.push({ options, resolve, reject });
    processQueue();
  });
}

// Function to confirm the current dialog
export function confirm() {
  const request = dialogQueue[0];
  if (request) {
    if (
      type.value === 'input' &&
      inputType.value === 'confirmation' &&
      textValue.value !== confirmationText.value
    ) {
      return;
    }
    request.resolve(type.value === 'confirm' ? true : textValue.value);
  }
}

// Function to cancel the current dialog
export function cancel() {
  const request = dialogQueue[0];
  if (request) {
    request.reject(type.value === 'confirm' ? false : null);
  }
}

// Computed property to disable the confirm button when input type is 'confirmation'
// and the input value doesn't match the expected confirmation text
export const disableConfirm = computed(() => {
  if (type.value === 'input' && inputType.value === 'confirmation') {
    return textValue.value !== confirmationText.value;
  }
  return false;
});
