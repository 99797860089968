<template>
  <v-dialog scrollable data-test-id="error-dialog" :model-value="dialogStore.show" persistent max-width="600px">
    <component :is="getDialogComponent(dialog.error)" v-if="dialog" :dialog="dialog" @dismiss-dialog="dismissDialog" @execute-callback="executeCallback"></component>
  </v-dialog>
</template>

<script setup lang="ts">
import { useDialog } from '@/composable/useDialog';

const { dialogStore, dialog, dismissDialog, executeCallback, getDialogComponent, getDialogTitle } = useDialog();

</script>
