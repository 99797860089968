import { ref, Ref, watch, reactive, readonly } from 'vue';
import { useAlert } from '@/composable/useAlert';

const { alertError } = useAlert();

class ResolvablePromise<T> {
  private _data: any;

  constructor(private _promise: Promise<T>) {
    this._data = reactive({ isResolved: false });

    _promise
      .then(() => {
        this._data.isResolved = true;
      })
      .catch(() => {
        this._data.isResolved = true;
      });
  }

  get isResolved() {
    return readonly(this._data).isResolved;
  }

  get promise() {
    return this._promise;
  }
}

const isLoading = ref(false);
const promiseQueue: Ref<ResolvablePromise<any>[]> = ref([]);

export const useLoadingWithPromises = () => {
  const updateLoadingState = () => {
    isLoading.value = promiseQueue.value.some((promise) => !promise.isResolved);
  };

  watch(promiseQueue, updateLoadingState, { deep: true });

  const addPromise = (promise: Promise<any>) => {
    const wrappedPromise = new ResolvablePromise(promise);
    promiseQueue.value.push(wrappedPromise);
    wrappedPromise.promise.catch((error) => {
      console.error(error);
      // issue a notification to the user
      alertError(error);
    });
  };

  return {
    isLoading,
    addPromise,
    promiseQueue,
  };
};
