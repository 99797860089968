import { env } from '@/env';
import i18next, { i18n, TOptions } from 'i18next';

import Fluent from 'i18next-fluent';

import { ref, Ref } from 'vue';
import {
  defaultLocale,
  I18N_FALLBACK_NAMESPACE,
  SupportedLocales,
  I18nNamespace,
} from '@/i18n';

interface I18next {
  i18next: i18n;
  ready: Readonly<Ref<boolean>>;
  initialized: Promise<void>;
}

const saveStorage = function (key, data) {
  localStorage.setItem(key, JSON.stringify(data));
};

const getStorage = function (key) {
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key) as string);
  }
};

let initResolve: (value: void) => void;
let initReject: (reason?: any) => void;

const ready = ref(false);
const preferredLocale: SupportedLocales = (getStorage('preferredLocale') ??
  defaultLocale) as SupportedLocales;
const initialized = new Promise<void>((resolve, reject) => {
  initResolve = resolve;
  initReject = reject;
});
i18next.use(Fluent);
if (!env().isTestMode) {
  const namespaces = Object.values(I18nNamespace); // Get enum values as array
  import('i18next-fluent-backend').then((FluentBackend) => {
    i18next
      .use(FluentBackend.default)
      .init({
        lng: preferredLocale,
        fallbackLng: defaultLocale,
        ns: [...namespaces, ...(env().isDevMode ? ['fluent-demo'] : [])],
        fallbackNS: I18N_FALLBACK_NAMESPACE,
        debug: env().isI18NDebugLogging,
      })
      .then(() => {
        ready.value = true;
        initResolve();
      })
      .catch((error) => {
        initReject(error);
      });
  });
}

export const useI18next = (): I18next => ({
  i18next,
  ready,
  initialized,
});

/**
 * Exposes the raw translation function of i18next.
 * If you need invalidation support use 'useTranslation' instead.
 */
export const useTranslator = () => i18next.t;

const locale = ref<SupportedLocales>(preferredLocale);
// const { current: vuetifyLocale } = useVuetifyLocale();
const setLocale = (newLocale: SupportedLocales) =>
  i18next.changeLanguage(newLocale).then(() => {
    console.debug('changing to:', newLocale);
    locale.value = newLocale;
    saveStorage('preferredLocale', locale.value);
    // vuetifyLocale.value = newLocale;
  });

interface Locale {
  locale: Readonly<Ref<SupportedLocales>>;
  setLocale: (newLocale: SupportedLocales) => Promise<void>;
}

export const useLocale = (): Locale => ({
  locale,
  setLocale,
});

/**
 * Used by the 'useTranslation' composable to allow translations to be marked
 * invalid for certain i18next events. Inspired by i18next-vue for Vue 3.
 */
const invalidationTrigger = ref(new Date());
const invalidate = (trigger: string) => {
  invalidationTrigger.value = new Date();
  if (env().isI18NDebugLogging) {
    console.debug(
      `i18next event '${trigger}' received. Translations invalidated.`
    );
  }
};
i18next.on('languageChanged', () => invalidate('languageChanged'));
i18next.on('loaded', () => invalidate('loaded'));
i18next.on('added', () => invalidate('added'));
i18next.on('removed', () => invalidate('removed'));

const withInvalidation =
  <P extends any[], R>(f: (...args: P) => R) =>
  (...args: P): R => {
    // access invalidationTrigger to mark invalid when changed
    invalidationTrigger.value;
    return f(...args);
  };

/**
 * Define own translation type as the type inference of the withInvalidation wrapper
 * forces to always hand over second parameter
 */
type SimpleTFunction = (key: string, options?: TOptions | string) => string;
/**
 * Use this in Composition API to rerender the component whenever necessary.
 * The translation function must be invoked within a computed to take effect.
 */
export const useTranslation = (): SimpleTFunction => {
  const reactiveT = withInvalidation(i18next.t);
  return reactiveT as SimpleTFunction;
};

/**
 * Similar to 'useTranslation' but allows to configure the namespace
 */
export const useTranslationNamespace = (
  namespace: I18nNamespace
): SimpleTFunction => {
  const reactiveT = useTranslation();
  return (key: string, options?: TOptions | string) => {
    if (typeof options === 'string') {
      options = { defaultValue: options };
    }
    const optionsToUse = {
      ...options,
      ns: [namespace, I18N_FALLBACK_NAMESPACE],
    };
    return reactiveT(key, optionsToUse);
  };
};

/**
 * Define the SimpleTranslateErrorFunction type.
 */
type SimpleTranslateErrorFunction = (
  key: string,
  defaultMessage: string,
  options?: TOptions
) => string;

/**
 * Use this composable function in the Composition API to safely translate error messages with a fallback defaultMessage.
 * @returns {SimpleTranslateErrorFunction} The error translation function.
 */
export const useErrorMessageTranslation: () => SimpleTranslateErrorFunction =
  () => {
    const t = useTranslation();

    /**
     * Translates an error message using the provided translation key, options, and a fallback defaultMessage.
     * @param {string} key - The translation key for the error message.
     * @param {string} defaultMessage - The default message to return if the translation fails.
     * @param {TOptions} [options] - The translation options.
     * @returns {string} The translated error message or the defaultMessage in case of a failure.
     */
    const translateErrorMessage: SimpleTranslateErrorFunction = (
      key,
      defaultMessage,
      options
    ) => {
      try {
        const translation = t(key, {
          defaultValue: defaultMessage,
          ...options,
        });

        // Check if the translation is successful (not returning the key itself)
        if (translation !== key) {
          return translation;
        } else {
          console.error(`Translation failed for key: ${key}`);
          return defaultMessage;
        }
      } catch (error) {
        console.error(`Error translating key: ${key}`, error);
        return defaultMessage;
      }
    };

    return translateErrorMessage;
  };
