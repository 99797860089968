<template>
  <v-card class="elevation-12" :loading="loading">
    <slot name="logo"><LoginBoxCardLogo /></slot>
    <v-divider />
    <LoginBoxCardTitle><slot name="title"></slot></LoginBoxCardTitle>
    <slot name="body">
      <v-card-text>
        <slot name="text"></slot>
      </v-card-text>
      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>
    </slot>
  </v-card>
</template>

<script lang="ts">
/**
 * Card to display login box components
 * TODO: see if this could be moved to branded card instead
 */
import LoginBoxCardLogo from '@/components/login-box/LoginBoxCardLogo.vue';
import LoginBoxCardTitle from '@/components/login-box/LoginBoxCardTitle.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoginBoxCard',
  components: {
    LoginBoxCardTitle,
    LoginBoxCardLogo,
  },
  props: {
    /**
     * Whether to show to loading bar
     */
    loading: {
      type: Boolean,
    },
  },
});
</script>

<style scoped>

</style>
