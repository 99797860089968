import { ref, readonly } from 'vue';
import type { Router } from 'vue-router';

/**
 * A function that will be used to resolve the router instance.
 */
let routerResolve: (router: Router) => void;

/**
 * A ref to track if the router is ready.
 */
const routerReady = ref(false);

/**
 * A promise that resolves with the router instance.
 * This promise is used to ensure that parts of the application
 * that depend on the router do not execute until the router is ready.
 */
const routerInstance = new Promise<Router>((resolve) => {
  routerResolve = resolve;
});

/**
 * Set the router as ready. This function should be called
 * once the router is initialized and ready to be used.
 */
const setRouterReady = () => (routerReady.value = true);

/**
 * A readonly ref that external components can watch to know
 * if the router is ready.
 */
const isRouterReady = readonly(routerReady);

/**
 * Resolve the router instance.
 * This function should be called with the router instance
 * once it is initialized, and it will resolve the `routerInstance` promise.
 * @param router - The initialized Vue Router instance.
 */
const resolveRouterInstance = (router: Router) => {
  routerResolve(router);
  setRouterReady();
};

/**
 * UseDynamicRouter composable.
 * Provides access to the router instance, a flag indicating if the router is ready,
 * and a method to resolve the router instance.
 * @returns An object containing the router instance promise, a readiness flag,
 *          and a method to resolve the router instance.
 */
export const useDynamicRouter = () => {
  return {
    routerInstance,
    isRouterReady,
    resolveRouterInstance,
  };
};
