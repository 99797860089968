<template>
  <v-dialog
    :model-value="showLoginModal"
    :open-on-click="false"
    :persistent="false"
    @update:model-value="(v) => v || inputEventHandler()"
    @click:outside="onClickOutside"
  >
    <template #activator="{}"> </template>
    <login-box v-if="showLoginModal" :auth-send="send" :auth-state="state" />
  </v-dialog>
</template>

<script lang="ts">
import { useAppService } from '@/statemachines/app.machine';
import { defineComponent, computed } from 'vue';
import LoginBox from '@/components/login-box/LoginBox.vue';
import { useActor } from '@xstate/vue';
import { useAuthService } from '@/statemachines/auth.machine';

export default defineComponent({
  name: 'TheLoginDialog',
  components: { LoginBox },
  setup() {
    const appService = useAppService();
    const credentialsService = useActor(
      appService.state.value.children.credentialsMachine
    );
    const { state, send } = useAuthService();
    const showLoginModal = computed(() => {
      return credentialsService.state.value.matches('loginBoxShown');
    });
    const onClickOutside = () => {
      sendCloseEvent();
    };
    const sendCloseEvent = () => {
      credentialsService.send('HIDE_LOGIN_BOX');
      send('RESTART');
    };
    const sendOpenEvent = () => {
      credentialsService.send('SHOW_LOGIN_BOX');
    };
    const show = false;
    const inputEventHandler = () => {
      sendCloseEvent();
    };
    return {
      showLoginModal,
      sendCloseEvent,
      sendOpenEvent,
      inputEventHandler,
      onClickOutside,
      show,
      credentialsService,
      state,
      send,
    };
  },
});
</script>
