const baseConfig = Object.freeze({
  dataFormat: 'json',
});

/**
 * Base class for API access
 */
export class BaseApi {
  baseUrl: string;
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getBaseConfig() {
    return baseConfig;
  }

  /**
   * Encode data as x-www-form-encoded
   * @param data
   * @returns {string}
   */
  encodeAsFormData(data: any) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  }
}
