import { SupportedLocales } from '@/i18n';
import dotenv from 'dotenv';
import { DEFAULT_FORM_DEBOUNCE_WAIT_MS } from './constants/forms';

// If the process is undefined, and we haven't left a marker that we're running under Vite
// process the dotenv file manually.
// This implies we are running under commonjs mode.
if (
  typeof process !== 'undefined' &&
  process.env.ENV_SET_BY_VITE === undefined
) {
  dotenv.config();
}

type XMLDepositApiHost =
  | 'https://doi.crossref.org'
  | 'https://test.crossref.org';
type ResetPasswordURL =
  | 'https://authenticator.crossref.org/reset-password/'
  | 'https://authenticator.sandbox.crossref.org/reset-password/'
  | 'https://authenticator.staging.crossref.org/reset-password/';

export const SUPPORTED_LOCALES = ['en', 'de', 'es', 'fr', 'id'] as const;

// Test if we are running under test mode
const isTestMode = (NODE_ENV: string) => {
  return NODE_ENV === 'test'
}

const isDepositApiHost = (
  value: string | undefined
): value is XMLDepositApiHost => {
  return (
    value === 'https://doi.crossref.org' ||
    value === 'https://test.crossref.org'
  );
};

const isResetPasswordURL = (
  value: string | undefined
): value is ResetPasswordURL => {
  return (
    value === 'https://authenticator.crossref.org/reset-password/' ||
    value === 'https://authenticator.sandbox.crossref.org/reset-password/' ||
    value === 'https://authenticator.staging.crossref.org/reset-password/'
  );
};

const canEnableKeycloak = (enabled: string, url: string, NODE_ENV: string): boolean => {
  // Don't enable keycloak if we're in test mode (unit tests)
  if (isTestMode(NODE_ENV)) return false
  return enabled === 'true' && url.length > 0;
};

const isSentry = (release: string, dsn: string): boolean => {
  return dsn.length > 0;
};

const getSentryEnvironment = (
  sentryEnvironment: string,
  NODE_ENV?: string
): string => {
  const nodeEnv = NODE_ENV || 'not-defined';
  return sentryEnvironment.length > 0 ? sentryEnvironment : nodeEnv;
};

const isUsersnap = (apiKey: string, globalApiKey: string): boolean => {
  return apiKey.length > 0 && globalApiKey.length > 0;
};

const isSupportedLocale = (
  locale: string
): locale is (typeof SUPPORTED_LOCALES)[number] => {
  return SUPPORTED_LOCALES.some((el) => el === locale);
};

const filterToSupportedLocales = (
  localesEnabled: string[]
): SupportedLocales[] => {
  return localesEnabled.filter(isSupportedLocale);
};

const getlocalesEnabled = (localesEnabled: string): SupportedLocales[] => {
  return filterToSupportedLocales(localesEnabled.split(','));
};

const getFormDebounceWait = (wait: string) => {
  return parseInt(wait);
};
/**
 * All new code should reference these configuration parameters.
 */
interface Environment {
  BASE_URL: string;
  formDebounceWait: number;
  isDevMode: boolean;
  isTestMode: boolean;
  isDevSettingsMenu: boolean;
  isHttpMocking: boolean;
  isJournalArticleFormEnabled: boolean;
  isKeycloakEnabled: boolean;
  isKeycloakDebug: boolean;
  isKeycloakVisible: boolean;
  isSentry: boolean;
  isXstateInspector: boolean;
  isI18NDebugLogging: boolean;
  isUsersnap: boolean;
  usersnapApiKey: string;
  usersnapGlobalApiKey: string;
  keymakerAPIBaseURL: string;
  keycloakServerURL: string;
  keycloakClientId: string;
  keymakerPathPrefix: string;
  xmlDepositApiHost: XMLDepositApiHost;
  resetPasswordURL: ResetPasswordURL;
  sentryDSN: string;
  sentryEnvironment: string;
  sentryRelease: string;
  localesEnabled: SupportedLocales[];
  showThemePalette: boolean;
  convertApiEndpoint: string;
  ALLOW_HTTP_MOCKING_FOR_TESTS: string;
  MATOMO_INSTANCE_URL: string;
  MATOMO_SITE_ID: string;
  FORM_DEBOUNCE_WAIT_MS: string;
}

/**
 * @deprecated referenced only by legacy code
 */
interface LegacyEnvironment {
  GENERATE_ID: string;
  FORGOT_PASSWORD_LINK: string;
  REDIRECT_ON_ROLE_AUTHORISATION: string | boolean;
  HTTP_MOCKING: string | boolean;
  LOCALES_ENABLED: string | undefined;
  AUTH_API_BASE_URL: string;
  NODE_ENV: string | undefined;
  ENABLE_XSTATE_INSPECTOR: string | boolean;
  KEYMAKER_PATH_PREFIX: string;
  DEPOSIT_API_HOST: string;
  USERSNAP_API_KEY: string;
  USERSNAP_GLOBAL_API_KEY: string;
  SENTRY_DSN: string;
  SENTRY_ENVIRONMENT: string;
  SENTRY_RELEASE: string;
  SHOW_SETTINGS_COG: string;
}

interface EnviromentIncludingLegacy extends Environment, LegacyEnvironment {}

declare global {
  interface Window {
    env: EnviromentIncludingLegacy;
  }
}

let windowConfig: any = {};

if (typeof window !== 'undefined') {
  window.env = window.env || {};
  windowConfig = window.env;
}

const mergedConfiguration = { ...windowConfig, ...process.env };

// For variables to be  automatically read from .env files by vue-cli, they must be prefixed
// with VUE_APP_ or VITE_. We rename them here, and set default values.
export const env = (): EnviromentIncludingLegacy => {
  const {
    VUE_APP_GENERATE_ID: GENERATE_ID = 'my-default',
    VUE_APP_PASSWORD_RESET_URL:
      FORGOT_PASSWORD_LINK = 'https://authenticator.crossref.org/reset-password/',
    VUE_APP_REDIRECT_ON_ROLE_AUTHORISATION:
      REDIRECT_ON_ROLE_AUTHORISATION = 'false',
    VUE_APP_HTTP_MOCKING: HTTP_MOCKING = 'false',
    VUE_APP_ALLOW_HTTP_MOCKING_FOR_TESTS:
      ALLOW_HTTP_MOCKING_FOR_TESTS = 'false',
    VUE_APP_LOCALES_ENABLED: LOCALES_ENABLED = 'en',
    VUE_APP_AUTH_API_BASE_URL: AUTH_API_BASE_URL = 'https://test.crossref.org',
    VUE_APP_KEYMAKER_API_BASE_URL:
      KEYMAKER_API_BASE_URL = 'https://127.0.0.1:8443/auth',
    VUE_APP_SHOW_I18N_DEBUG_MESSAGES: SHOW_I18N_DEBUG_MESSAGES = 'false',
    VUE_APP_I18N_DEBUG_LOGGING: IS_I18N_DEBUG = 'false',
    VUE_APP_ENABLE_XSTATE_INSPECTOR: ENABLE_XSTATE_INSPECTOR = 'false',
    VUE_APP_IS_XSTATE_INSPECTOR: IS_XSTATE_INSPECTOR = 'false',
    VUE_APP_JOURNAL_ARTICLE_FORM_ENABLED:
      JOURNAL_ARTICLE_FORM_ENABLED = 'false',
    VUE_APP_KEYCLOAK_ENABLED: KEYCLOAK_ENABLED = 'false',
    VUE_APP_KEYCLOAK_VISIBLE: KEYCLOAK_VISIBLE = 'false',
    VUE_APP_IS_KEYCLOAK_DEBUG_ENABLED: KEYCLOAK_DEBUG_ENABLED = 'false',
    VUE_APP_KEYCLOAK_URL: KEYCLOAK_SERVER_URL = 'https://127.0.0.1:8443/auth',
    VUE_APP_KEYCLOAK_CLIENT_ID: KEYCLOAK_CLIENT_ID = 'mycrossref',
    VUE_APP_KEYMAKER_PATH_PREFIX: KEYMAKER_PATH_PREFIX = '/keys',
    VUE_APP_XML_DEPOSIT_API_HOST:
      DEPOSIT_API_HOST = 'https://test.crossref.org',
    VUE_APP_USERSNAP_API_KEY: USERSNAP_API_KEY = '',
    VUE_APP_USERSNAP_GLOBAL_API_KEY: USERSNAP_GLOBAL_API_KEY = '',
    VUE_APP_SHOW_SETTINGS_COG: SHOW_SETTINGS_COG = 'false',
    VUE_APP_DEV_SETTINGS_MENU: IS_DEV_SETTINGS = 'false',
    VUE_APP_SENTRY_RELEASE: SENTRY_RELEASE = '',
    VUE_APP_SENTRY_ENVIRONMENT: SENTRY_ENVIRONMENT = '',
    VUE_APP_SENTRY_DSN: SENTRY_DSN = '',
    VUE_APP_SHOW_THEME_PALETTE: SHOW_THEME_PALETTE = false,
    VUE_APP_BASE_URL: BASE_URL = 'http://localhost:8000',
    VUE_APP_CONVERT_API_ENDPOINT:
      CONVERT_API_ENDPOINT = 'https://api.crossref.org/beta/convert',
    VUE_APP_MATOMO_INSTANCE_URL:
      MATOMO_INSTANCE_URL = 'https://crossref.matomo.cloud',
    VUE_APP_MATOMO_SITE_ID: MATOMO_SITE_ID = '',
    VUE_APP_FORM_DEBOUNCE_WAIT_MS:
      FORM_DEBOUNCE_WAIT_MS = DEFAULT_FORM_DEBOUNCE_WAIT_MS,
    NODE_ENV,
  } = mergedConfiguration;
  if (!isDepositApiHost(DEPOSIT_API_HOST)) {
    throw new Error(
      `Invalid environment configuration for Deposit Api Host: ${DEPOSIT_API_HOST}`
    );
  }
  if (!isResetPasswordURL(FORGOT_PASSWORD_LINK)) {
    throw new Error(
      `Invalid environment configuration for Deposit Api Host: ${DEPOSIT_API_HOST}`
    );
  }
  const finalConfiguration = {
    BASE_URL: BASE_URL,
    isDevMode: NODE_ENV === 'development',
    isTestMode: isTestMode(NODE_ENV),
    isDevSettingsMenu: IS_DEV_SETTINGS === 'true',
    isHttpMocking: HTTP_MOCKING === 'true',
    isI18NDebugLogging: IS_I18N_DEBUG === 'true',
    isJournalArticleFormEnabled: JOURNAL_ARTICLE_FORM_ENABLED === 'true',
    isKeycloakEnabled: canEnableKeycloak(
      KEYCLOAK_ENABLED,
      KEYCLOAK_SERVER_URL,
      NODE_ENV
    ),
    isKeycloakVisible: KEYCLOAK_VISIBLE === 'true',
    isKeycloakDebug: KEYCLOAK_DEBUG_ENABLED === 'true',
    keycloakServerURL: KEYCLOAK_SERVER_URL,
    keycloakClientId: KEYCLOAK_CLIENT_ID,
    keymakerPathPrefix: KEYMAKER_PATH_PREFIX,
    keymakerAPIBaseURL: KEYMAKER_API_BASE_URL,
    isXstateInspector: IS_XSTATE_INSPECTOR === 'true',
    isSentry: isSentry(SENTRY_RELEASE, SENTRY_DSN),
    isUsersnap: isUsersnap(USERSNAP_API_KEY, USERSNAP_GLOBAL_API_KEY),
    localesEnabled: getlocalesEnabled(LOCALES_ENABLED),
    xmlDepositApiHost: DEPOSIT_API_HOST,
    resetPasswordURL: FORGOT_PASSWORD_LINK,
    usersnapApiKey: USERSNAP_API_KEY,
    usersnapGlobalApiKey: USERSNAP_GLOBAL_API_KEY,
    sentryDSN: SENTRY_DSN,
    sentryEnvironment: getSentryEnvironment(SENTRY_ENVIRONMENT, NODE_ENV),
    sentryRelease: SENTRY_RELEASE,
    showThemePalette: SHOW_THEME_PALETTE,
    convertApiEndpoint: CONVERT_API_ENDPOINT,
    formDebounceWait: getFormDebounceWait(FORM_DEBOUNCE_WAIT_MS),
    ALLOW_HTTP_MOCKING_FOR_TESTS,
    MATOMO_INSTANCE_URL,
    MATOMO_SITE_ID,
    GENERATE_ID,
    FORGOT_PASSWORD_LINK,
    REDIRECT_ON_ROLE_AUTHORISATION: REDIRECT_ON_ROLE_AUTHORISATION === 'true',
    HTTP_MOCKING,
    LOCALES_ENABLED,
    AUTH_API_BASE_URL,
    NODE_ENV,
    ENABLE_XSTATE_INSPECTOR,
    DEPOSIT_API_HOST,
    USERSNAP_API_KEY,
    USERSNAP_GLOBAL_API_KEY,
    SENTRY_DSN,
    SENTRY_ENVIRONMENT,
    SENTRY_RELEASE,
    SHOW_SETTINGS_COG,
    SHOW_I18N_DEBUG_MESSAGES,
    KEYMAKER_PATH_PREFIX,
    FORM_DEBOUNCE_WAIT_MS,
  };
  return finalConfiguration;
};
