/**
 * Alert machine
 * Handle the queueing and display of notifications
 */
import { Alert, AlertWithId } from '@/common/types';
import { InjectionKey } from 'vue';
import { createMachine, InterpreterFrom, assign, ActorRefFrom } from 'xstate';

/**
 * The extended context of the state machine
 */
type AlertContext = {
  msgQueue: AlertWithId[];
  currentNotification: AlertWithId | null;
  currentErrorNotification: AlertWithId | null;
  count: number;
  autoDismissNotifications: boolean;
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    // Create the context with sane default values
    context: {
      msgQueue: [],
      currentNotification: null,
      currentErrorNotification: null,
      count: 0,
      autoDismissNotifications: true,
    } as AlertContext,
    // XState's automatic typegeneration
    tsTypes: {} as import('./alert.machine.typegen').Typegen0,
    schema: {
      context: {} as AlertContext,
      /**
       * The events that the state machine will listen for, and any data passed with them
       */
      events: {} as
        | { type: 'ALERT'; alert: Alert }
        | { type: 'CLEAR' }
        | { type: 'DISMISS'; notification: AlertWithId }
        | { type: 'TOGGLE_AUTODISMISS' },
    },
    initial: 'checkIfThereAreMoreNotifications',
    id: 'Notifications Machine',
    on: {
      TOGGLE_AUTODISMISS: {
        actions: 'toggleAutoDismiss',
      },
    },
    states: {
      idle: {
        on: {
          TOGGLE_AUTODISMISS: {
            target: '',
            actions: 'toggleAutoDismiss',
          },
          ALERT: {
            actions: ['queueMessage'],
            target: 'checkIfThereAreMoreNotifications',
          },
        },
      },
      displayingNotification: {
        entry: 'assignOldestNotificationToCurrent',
        /**
         * After 2000ms, if it is not an error message, automatically
         * transition to the dismissingNotification state
         */
        after: {
          2000: {
            cond: 'notificationShouldBeAutoDismissed',
            target: 'dismissingNotification',
          },
        },
        /**
         * Listen for alerts and dissmissals while the notification is being diplayed
         */
        on: {
          ALERT: {
            actions: ['queueMessage'],
          },
          DISMISS: {
            target: 'dismissingNotification',
            internal: false,
          },
        },
      },
      dismissingNotification: {
        /**
         * Listen only for alerts when the notification is being dismissed
         */
        on: {
          ALERT: {
            actions: ['queueMessage'],
          },
        },
        /**
         * Perform the actual dismissal after 500ms, to allow the notification transition
         * to complete in the User Interface
         */
        after: {
          500: {
            actions: 'dismissNotification',
            target: '#Notifications Machine.checkIfThereAreMoreNotifications',
          },
        },
      },
      checkIfThereAreMoreNotifications: {
        /**
         * Always, immediately, transition to either 'displayingNotifications', if there are
         * notifications waiting in the queue, or to the 'idle' state.
         */
        always: [
          {
            cond: 'thereAreMoreNotifications',
            target: 'displayingNotification',
          },
          {
            target: 'idle',
          },
        ],
      },
    },
  },
  {
    guards: {
      /**
       * Check whether there are outstanding notifications in the queue
       * @param context
       * @returns boolean
       */
      thereAreMoreNotifications: (context) => {
        return context.msgQueue.length > 0;
      },
      /**
       * Test whether a non-error notification should be automatically dismissed
       */
      notificationShouldBeAutoDismissed: (context) => {
        const notError = context.currentNotification?.level !== 'error';
        const autoDismissEnabled = context.autoDismissNotifications;
        return notError && autoDismissEnabled;
      },
    },
    actions: {
      /**
       * Put the oldest notification into the slot to be displayed
       */
      assignOldestNotificationToCurrent: assign((context) => {
        const oldestItem = context.msgQueue.shift();
        return {
          currentNotification: oldestItem,
          msgQueue: context.msgQueue,
        };
      }),
      /**
       * Push a new notification onto the queue
       */
      queueMessage: assign((context, event) => {
        const newCount = context.count + 1;
        const timeout = event.alert?.timeout || -1;
        const alert: AlertWithId = {
          id: newCount,
          msg: event.alert.msg,
          level: event.alert.level || 'error',
          timeout: timeout,
        };
        const queue = context.msgQueue;
        queue.push(alert);
        return {
          msgQueue: queue,
          count: newCount,
        };
      }),
      /**
       * Dismiss the current notification
       */
      dismissNotification: assign((_context, _event) => {
        return {
          currentNotification: undefined,
        };
      }),
      /**
       * Toggle auto-dismissal of notifications
       */
      toggleAutoDismiss: assign((context) => {
        return {
          autoDismissNotifications: !context.autoDismissNotifications,
        };
      }),
    },
  }
);

/**
 * Use the XState generic type helpers to generate types for the machine
 */
export type AlertService = InterpreterFrom<typeof machine>;
export type AlertActor = ActorRefFrom<typeof machine>;
export const AlertServiceSymbol: InjectionKey<AlertService> =
  Symbol('alert.service');
export const AlertActorSymbol: InjectionKey<AlertActor> = Symbol('alert.actor');
