<script setup lang="ts">
import { AuthenticatorLoggedInUser } from '@/composable/useAuth';
import TheUserMenu from './TheUserMenu.vue';
import { env } from '@/env';

const emit = defineEmits<{
  (event: 'login');
  (event: 'logout');
}>()
const props = defineProps<{
  user: AuthenticatorLoggedInUser | null
}>()

</script>
<template>
    <TheUserMenu
      v-if="user"
      :username="user.username"
      @LOGOUT_REQUEST="emit(
      'logout'
    )"
    />
</template>
