import { RouteNames } from '@/router';
import { resolveTitle } from './../utils/routes';
import { computed, ComputedRef } from 'vue';
import { RouteRecordRaw, useRoute } from 'vue-router';
import { env } from '@/env';
import { useUserInfo } from './useAuth';
import { useTranslation, useTranslationNamespace } from './useI18n';
import { I18nNamespace } from '@/i18n';

export interface MenuItem {
  title: string;
  name?: string;
  path?: string;
  icon?: string;
  children: MenuItem[];
}

interface UseMenuItems {
  menuItems: ComputedRef<MenuItem[]>;
}

function parseRoutes(
  routes: RouteRecordRaw[],
  parentPath = '',
  isStaff: boolean,
  menuItems: MenuItem[] = []
): MenuItem[] {
  if (!routes.length) {
    return menuItems;
  }

  const currentRoute = useRoute();
  const route = routes[0];

  // If the route requires a staff role, and the current user is not staff
  // don't add the route to the navigation menu
  if (route.meta && route.meta.requiredRole === 'ROLE_STAFF') {
    if (!isStaff) {
      return parseRoutes(routes.slice(1), parentPath, isStaff, menuItems);
    }
  }

  // If the route is marked as hidden, or its breadcrumb evaluates to false, don't add it
  if (
    route.meta &&
    (route.meta.hideNavMenuEntry || route.meta.breadcrumb === false)
  ) {
    return parseRoutes(routes.slice(1), parentPath, isStaff, menuItems);
  }

  // If the route should not be shown in the nav menu, remove it and continue
  if (route.meta?.hideNavMenuEntry) {
    return parseRoutes(routes.slice(1), parentPath, isStaff, menuItems);
  }
  const title = resolveTitle(route);

  const isNested = !route.path.startsWith('/');
  // Concatenate nested paths with their parent's path, separated by a / if required
  const fullPath = isNested
    ? `${parentPath}${parentPath.endsWith('/') ? '' : '/'}${route.path}`
    : route.path;

  const menuItem: MenuItem = {
    title: title || route.name?.toString() || '',
    path: fullPath,
    icon: route.meta && route.meta.icon ? (route.meta.icon as string) : '',
    children: [],
  };

  if (route.children && route.children.length) {
    menuItem.children = parseRoutes(route.children, menuItem.path, isStaff, []);
  }

  return parseRoutes(routes.slice(1), parentPath, isStaff, [
    ...menuItems,
    menuItem,
  ]);
}

const { isStaff } = useUserInfo();
const t = useTranslation();
const tKeyManager = useTranslationNamespace(I18nNamespace.KeyManager);

export function useMenuItemsFromRouteConfig(
  routes: RouteRecordRaw[],
  isStaff: ComputedRef<boolean>
): UseMenuItems {
  const menuItems: ComputedRef<MenuItem[]> = computed(() => {
    // Wrap the parseRoutes function inside a computed property
    const parseRoutesWrapper = computed(() =>
      parseRoutes(routes, '/', isStaff.value)
    );

    return parseRoutesWrapper.value;
  });

  return { menuItems };
}

export const useMenuItems = (): UseMenuItems => {
  const menuItems: ComputedRef<MenuItem[]> = computed(() => {
    const items: MenuItem[] = [
      {
        name: RouteNames.records.index,
        title: t('record-registration-title'),
        children: [],
      },
    ];
    if (env().isKeycloakEnabled) {
      const keycloakMenuChildren = isStaff.value
        ? [
            {
              name: RouteNames.keyManager.organisation.create,
              title: tKeyManager('keys_createOrganisation'),
              children: [],
            },
          ]
        : [];
      items.push({
        name: RouteNames.keyManager.organisation.index,
        title: tKeyManager('key-manager-title'),
        children: keycloakMenuChildren,
      });
    }
    return items;
  });

  return { menuItems };
};
