<script setup lang="ts">

import {useTranslation} from "@/composable/useI18n";
import VisuallyHidden from "@/components/VisuallyHidden.vue";
const t = useTranslation();
const accessibleName = t('action-get-help');
const newTabWarning = t('opens-in-new-tab');
const props = defineProps<{
  url: string;
}>()
</script>

<template>
  <v-btn :href="url" :title="accessibleName" icon variant="text" target="_blank" rel="noopener">
    <v-icon icon="$help" color="primary" size="x-large"></v-icon>
    <visually-hidden>{{ accessibleName }} ({{ newTabWarning }})</visually-hidden>
  </v-btn>
</template>

<style scoped>

</style>
