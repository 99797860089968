<template>
  <div>
    <div></div>
    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <v-btn
          test-id="dev-settings"
          size="large"
          icon
          v-bind="props"
          @click.stop="settings = !settings"
        >
          <v-icon size="30" color="primary">mdi-cog</v-icon>
        </v-btn>
      </template>
      Settings
    </v-tooltip>
    <v-navigation-drawer v-model="settings" :scrim="false" temporary absolute>
      <v-toolbar flat>
        <v-toolbar-title>Settings</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon @click="settings = false">
            <v-icon>$close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-divider />

      <v-container>
        <v-row><v-col>Direction</v-col></v-row>
        <v-row>
          <v-col>
            <v-btn-toggle
              mandatory
              group
              color="primary"
              style="display: grid; grid-template-columns: 1fr 1fr"
            >
              <v-btn :value="false">
                <span class="hidden-sm-and-down">LTR</span>

                <v-icon end> mdi-format-textdirection-l-to-r</v-icon>
              </v-btn>

              <v-btn :value="true">
                <span class="hidden-sm-and-down">RTL</span>

                <v-icon end> mdi-format-textdirection-r-to-l </v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-container>

      <v-divider />

      <v-container>
        <v-row><v-col>Alerts</v-col></v-row>
        <v-row>
          <v-col>
            <v-btn @click="alertSendError">Error</v-btn>
          </v-col>
          <v-col>
            <v-btn @click="alertSendSuccess">Success</v-btn>
          </v-col>
          <v-col>
            <v-btn @click="toggleAutoDismiss">{{ autoDismiss() }}</v-btn>
            <v-switch
              label="Auto Dismiss"
              color="primary"
              :value="autoDismiss()"
              :model-value="autoDismiss()"
              @click="toggleAutoDismiss"
            ></v-switch>
          </v-col>
        </v-row>
      </v-container>

      <v-divider />

      <v-divider />

      <v-divider />
    </v-navigation-drawer>
  </div>
</template>

<script lang="ts">
import { useAppService } from '@/statemachines/app.machine';
import { defineComponent } from 'vue';
import { useActor } from '@xstate/vue';
export default defineComponent({
  name: 'Settings',
  setup() {
    const randomStr = (minLength = 0, acc = ''): string => {
      if (acc.length <= minLength) {
        const str = Math.random().toString(36).slice(2);
        return randomStr(minLength, acc.concat(str));
      }

      return acc.slice(0, minLength);
    };

    const appService = useAppService();
    const alertService = useActor(
      appService.state.value.children.notificationsMachine
    );
    const alertSendError = () => {
      const msgText = `'A test error alert ${randomStr(10)}!'
      <?xml version="1.0" encoding="UTF-8"?>
<doi_batch_diagnostic status="completed" sp="a-cs1">
   <submission_id>1433740896</submission_id>
   <batch_id>batch_000001</batch_id>
   <record_diagnostic status="Failure">
      <doi />
      <msg>Error: cvc-complex-type.2.4.b: The content of element 'project' is not complete. One of '{"http://www.crossref.org/grant_id/0.1.1":project-title, "http://www.crossref.org/grant_id/0.1.1":investigators, "http://www.crossref.org/grant_id/0.1.1":description, "http://www.crossref.org/grant_id/0.1.1":award_amount, "http://www.crossref.org/grant_id/0.1.1":funding}' is expected.
Error: cvc-datatype-valid.1.2.1: '' is not a valid value for 'date'.
Error: cvc-type.3.1.3: The value '' of element 'award-start-date' is not valid.
Error: cvc-complex-type.2.4.b: The content of element 'grant' is not complete. One of '{"http://www.crossref.org/relations.xsd":program, "http://www.crossref.org/grant_id/0.1.1":doi_data}' is expected.
</msg>
   </record_diagnostic>
   <batch_data>
      <record_count>1</record_count>
      <success_count>0</success_count>
      <warning_count>0</warning_count>
      <failure_count>1</failure_count>
   </batch_data>
</doi_batch_diagnostic>

`;
      alertService.send({
        type: 'ALERT',
        msg: msgText,
        alert: { msg: msgText, level: 'error' },
      });
    };
    const alertSendSuccess = () => {
      const msgText = `A test success alert  ${randomStr(10)}!`;
      alertService.send({
        type: 'ALERT',
        msg: msgText,
        alert: { msg: msgText, level: 'success' },
      });
    };
    const autoDismiss = () => {
      return alertService.state.value.context.autoDismissNotifications;
    };
    const toggleAutoDismiss = () => {
      alertService.send('TOGGLE_AUTODISMISS');
    };

    return {
      alertService,
      alertSendError,
      alertSendSuccess,
      autoDismiss,
      toggleAutoDismiss,
    };
  },
  data: function () {
    return {
      settings: false,
      locales: [
        { text: 'English (en)', value: 'en' },
        { text: 'German (de)', value: 'de' },
      ],
      breakHorizontals: [
        { text: 'None', value: false },
        { text: 'xs', value: 'xs' },
        { text: 'sm', value: 'sm' },
        { text: 'md', value: 'md' },
        { text: 'lg', value: 'lg' },
        { text: 'xl', value: 'xl' },
      ],
    };
  },
});
</script>
