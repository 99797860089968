<template>
  <div>
    <!--
    Triggered when the info icon is clicked
    @event onInfoIconClick
    -->
    <v-autocomplete
      :model-value="value"
      :items="roles"
      item-title="roleName"
      item-value="roleName"
      label="Select assigned role"
      prepend-icon="mdi-information"
      data-cy="role-select"
      @update:model-value="$emit('input', $event)"
      @click:prepend="onInfoIconClick"
    >
    </v-autocomplete>
    <v-expand-transition>
      <div v-if="showTooltip">
        <div class="font-weight-medium mb-2">Assigned role</div>
        <span class="mt-2 font-weight-light"
          >You have logged in using email credentials. More than one role is
          assigned to your profile, in this dropdown menu, select a role that is
          relevant to you.
          <a class="text-black" :href="learnMoreUrl">Learn more</a></span
        >
      </div>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * Renders a drop-down input showing the list of roles assigned to the user
 */
export default defineComponent({
  name: 'LoginBoxInputSelectRole',
  props: {
    /**
     * The name of the role selected (if known)
     */
    value: {
      type: String,
      default: null,
    },
    /**
     * The list of roles assigned to the user
     */
    roles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      /**
       * Whether to show the extended help text
       */
      showTooltip: false,
      /**
       * Link to documentation about user roles
       */
      learnMoreUrl: 'https://www.crossref.org/education/',
    };
  },
  methods: {
    /**
     * Called when the info icon is clicked
     * @param e
     */
    onInfoIconClick(e: any) {
      e.target?.blur();
      this.showTooltip = !this.showTooltip;
    },
  },
});
</script>

<style scoped>

</style>
