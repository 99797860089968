import axios, { AxiosRequestConfig } from 'axios';
import { BaseApi } from '@/api/BaseApi';

/**
 * API access class using Axios
 */
export class AxiosApi extends BaseApi {
  async fetch(url: string) {
    const { data } = await axios.get(`${this.baseUrl}${url}`);
    return data;
  }

  async post(url: string, data: any, config: any) {
    config = { ...this.getBaseConfig(), ...config };
    let options: AxiosRequestConfig = {};
    if (config.dataFormat === 'formData') {
      options = {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
      };
      data = this.encodeAsFormData(data);
    }
    if (config.token) {
      options.headers = options.headers || {};
      options.headers.authorization = `${config.token}`;
    }
    const fqUrl = `${this.baseUrl}${url}`;
    const response = await axios.post(fqUrl, data, options);
    return response;
  }
}
