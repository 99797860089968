import { Ref, computed, ref } from 'vue';

export type FormConfig = {
  config: Record<string, unknown>;
  styles: Record<string, unknown>;
};

type FieldStyleFill = 'filled' | 'outlined';

const fieldStyleFill: Ref<FieldStyleFill> = ref('outlined');

const fieldStyle = computed(() => ({
  variant: fieldStyleFill.value,
  density: 'default'
}));

const formThemes = computed(() => ({
  default: {
    config: {
      breakHorizontal: 'md',
      hideArraySummaryValidation: true,
      vuetify: {
        'v-text-field': fieldStyle.value,
        'v-combobox': fieldStyle.value,
        'v-autocomplete': fieldStyle.value,
        'v-textarea': fieldStyle.value,
        'v-select': fieldStyle.value,
        'v-expansion-panels': {
          flat: true,
          focusable: false,
        },
        'v-container': {
          fluid: true,
        },
      },
    },
    styles: {
      group: {
        root: 'pa-md-4',
      },
      arrayList: {
        root: 'pa-md-4 elevation-2',
        container: 'container--fluid',
        item: 'elevation-1',
      },
      verticalLayout: {
        root: 'myClass',
      },
      control: { root: 'my-control' },
    },
  },
  highcontrast: {
    config: {
      bare: true,
      breakHorizontal: 'md',
      hideArraySummaryValidation: true,
      vuetify: {
        'v-text-field': fieldStyle.value,
        'v-combobox': fieldStyle.value,
        'v-autocomplete': fieldStyle.value,
        'v-textarea': fieldStyle.value,
        'v-select': fieldStyle.value,
        'v-expansion-panels': {
          flat: true,
          focusable: false,
        },
        'v-container': {
          fluid: false,
        },
      },
    },
    styles: {
      group: {
        root: 'pa-0 elevation-0',
      },
      arrayList: {
        root: 'elevation-0',
        container: 'px-0',
        item: 'elevation-2',
        label: 'px-0',
      },
      horizontalLayout: {
        root: 'pa-0',
        item: 'pb-0 pt-2',
      },
      verticalLayout: {
        root: 'el elevation-0 pa-0',
        item: 'elevation-0',
      },
      vCard: {
        root: 'elevation-0',
      },
      control: { root: 'my-control' },
      vContainer: {
        root: 'pa-0',
      },
    },
  },
  grantForm: {
    config: {
      breakHorizontal: 'md',
      hideArraySummaryValidation: true,
      vuetify: {
        'v-text-field': fieldStyle.value,
        'v-combobox': fieldStyle.value,
        'v-autocomplete': fieldStyle.value,
        'v-textarea': fieldStyle.value,
        'v-select': fieldStyle.value,
        'v-expansion-panels': {
          flat: true,
          focusable: false,
        },
        'v-container': {
          fluid: false,
        },
      },
    },
    styles: {
      group: {
        root: 'pa-0',
      },
      arrayList: {
        root: 'elevation-2',
        container: 'container--fluid',
        item: 'elevation-1',
      },
      horizontalLayout: {
        root: 'pa-0',
        item: 'pt-2',
      },
      verticalLayout: {
        root: 'myClass el elevation-0 pa-0',
        item: 'elevation-0 pb-0',
      },
      vCard: {
        root: 'elevation-0 myVCard',
      },
      control: { root: 'my-control' },
      vContainer: {
        root: 'pa-0',
      },
    },
  },
}));

export type FormThemeName = keyof typeof formThemes.value;

const defaultFormTheme = ref<FormThemeName>('highcontrast');

const currentFormTheme = ref<FormThemeName>(defaultFormTheme.value);

const changeFormTheme = (themeName: FormThemeName) => {
  if (formThemes.value[themeName]) {
    currentFormTheme.value = themeName;
  }
};

const changeFieldStyleFill = (style: FieldStyleFill) =>
  (fieldStyleFill.value = style);

export default function useFormTheme() {
  return {
    currentFormTheme,
    changeFormTheme,
    formThemes,
    defaultFormTheme,
    fieldStyleFill,
    changeFieldStyleFill,
    fieldStyle,
  };
}
