import {
  useTranslation,
  useTranslationNamespace,
} from './../composable/useI18n';
import { KeycloakLoginOptions } from 'keycloak-js';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint no-console: ["error", { allow: ["error"] }] */
import {
  RouteRecordRaw,
  RouteLocationNormalized,
  createRouter,
  createWebHistory,
  RouteLocationNormalizedLoaded,
  RouteRecordNormalized,
} from 'vue-router';
import { unref } from 'vue';
import { env } from '@/env';
import { useKeycloak } from '@/composable/useKeycloak';
import { getBaseUrlFromUrl } from '@/utils/url/getBaseUrlFromUrl';
import setPageTitle from '@/utils/setPageTitle';
import { I18nNamespace } from '@/i18n';
import {resetGetHelpUrl, setGetHelpUrl} from "@/utils/routes";
import {URL_DOCS_RECORD_MANAGEMENT_TOOL} from "@/constants/urls";
import { useUserStore } from '@/stores/user';

/**
 * A union type that represents various forms of route objects within Vue Router.
 * This type is used to unify the handling of route data across different contexts,
 * such as navigation guards, route meta configuration, and dynamic title resolution.
 *
 * - `RouteRecordNormalized`: Represents a normalized route record with resolved paths and names.
 * - `RouteLocationNormalized`: Represents a normalized route location object after navigation has been confirmed.
 * - `RouteLocationNormalizedLoaded`: An extension of `RouteLocationNormalized` with resolved async components.
 * - `RouteRecordRaw`: Represents the raw user-defined route record before normalization.
 */
type RouteUnion =
  | RouteRecordNormalized
  | RouteLocationNormalized
  | RouteLocationNormalizedLoaded
  | RouteRecordRaw;

/**
 * Represents a function or value that resolves the title for a route. Can return a string,
 * null, or execute a function to determine the route's title dynamically.
 */
export type RouteTitleResolver =
  | string
  | null
  | undefined
  | ((route: RouteUnion) => string | null);

/**
 * Extends Vue Router's RouteMeta interface to include custom meta fields for
 * authentication, navigation, and title management.
 */
declare module 'vue-router' {
  interface RouteMeta {
    /** Indicates if the route requires authentication. */
    requiresAuth?: boolean;
    /** Does this route require Legacy Community Data Service authentication? */
    requiresCDSAuth?: boolean;
    /** Controls visibility of the navigation menu entry for this route. */
    hideNavMenuEntry?: boolean;
    /** Defines dynamic or static title for the route, which can be localized. */
    title?: RouteTitleResolver | string;
    /** Optionally hides the breadcrumb for this route. */
    hideBreadcrumb?: boolean;
    /** Specifies a required role for accessing the route. */
    requiredRole?: string;
    /** Flag to indicate an error page. */
    error?: boolean;
    /** Provides an error code for error pages, e.g., 404 for Not Found. */
    errorCode?: number;
  }
}

export const RouteNames = {
  keyManager: {
    index: 'keymanager.index',
    organisation: {
      create: 'keymanager.organizations.create',
      index: 'keymanager.index.choose-org',
    },
  },
  records: {
    index: 'contentRegistration.home',
  },
  notFound: 'not-found',
  index: 'home',
  login: 'Login',
} as const;

const t = useTranslation();
const keysT = useTranslationNamespace(I18nNamespace.KeyManager);

const ContentRegistrationHome = () =>
  import('@/views/ContentRegistrationHome.vue');
const ContentRegistrationNewRecord = () =>
  import('@/views/ContentRegistrationNewRecord.vue');
const ContentRegistrationForm = () =>
  import('@/views/ContentRegistrationForm.vue');
const SubmissionComplete = () =>
  import('@/views/ContentRegistrationSubmissionComplete.vue');
const MyCrossrefHome = () => import('@/views/Home.vue');
const ResourceManagementShowOrganization = () =>
  import('@/views/Organizations/Home.vue');
const Organizations = () => import('@/views/Organizations.vue');
const OrganizationChoose = () =>
  import('@/components/Organizations/OrganizationChoose.vue');
const OrganizationCreate = () => import('@/views/Organizations/Create.vue');
const AuthenticatorLoginPage = () =>
  import('@/views/AuthenticatorLoginPage.vue');
const Index = () => import('@/views/Index.vue');

/**
 * Attempts to log the user in using Keycloak if required by the route. If the user is
 * not authenticated or lacks the required role, it redirects to the login page or
 * throws an error.
 *
 * @param to - The target route the user is navigating to.
 * @param options - Optional Keycloak login options.
 */
const attemptLogin = async (
  to: RouteLocationNormalized,
  options?: KeycloakLoginOptions
) => {
  const keycloakStore = useKeycloak();
  let keycloakInstance;
  if (!unref(keycloakStore.isReady)) {
    try {
      keycloakInstance = await keycloakStore.instance;
    } catch (error) {
      console.error('Keycloak error during initialization', error);
      throw new Error('Keycloak initialization failed');
    }
  } else {
    keycloakInstance = await keycloakStore.instance;
  }
  if (keycloakInstance.authenticated) {
    if (to.meta?.requiredRole) {
      if (keycloakInstance.hasRealmRole?.(to.meta?.requiredRole)) {
        to.meta.error = false;
      } else {
        to.meta.error = true;
        to.meta.errorCode = 401;
        console.error('User does not have the required role');
        throw new Error('User does not have the required role');
      }
    }
  } else {
    let redirectUri;
    if (to.name !== 'login') {
      redirectUri = getBaseUrlFromUrl(window.location.origin + to.fullPath);
    } else {
      redirectUri = env().BASE_URL;
    }
    const loginUrl = keycloakInstance.createLoginUrl?.({
      // FIXME: this will need to be amended to take the fullPath for nested routes, with KC params stripped
      redirectUri: redirectUri,
    });
    if (!loginUrl) {
      console.error('A keycloak login URL could not be generated.');
      throw new Error('Keycloak login URL could not be generated');
    }
    window.location.replace(loginUrl);
  }
};

const protectedRoutes: RouteRecordRaw[] = [];
if (env().isKeycloakEnabled) {
  protectedRoutes.push({
    path: env().KEYMAKER_PATH_PREFIX,
    component: Organizations,
    name: RouteNames.keyManager.index,
    props: true,
    meta: {
      title: () => {
        return t('key-manager-title', 'Key manager');
      },
      requiresAuth: true,
      hideNavMenuEntry: !env().isKeycloakVisible,
    },
    children: [
      {
        path: '',
        component: OrganizationChoose,
        name: RouteNames.keyManager.organisation.index,
        meta: {
          title: 'Choose Organisation',
          hideBreadcrumb: true,
          requiresAuth: true,
          hideNavMenuEntry: true,
        },
      },
      {
        path: 'organizations',
        component: Organizations,
        name: 'Organizations',
        meta: {
          title: 'Organizations',
          requiresAuth: true,
          hideNavMenuEntry: true,
        },
        redirect: { name: 'keymanager.index' },
        children: [],
      },
      {
        path: 'create',
        component: OrganizationCreate,
        name: RouteNames.keyManager.organisation.create,
        props: true,
        meta: {
          requiresAuth: true,
          requiredRole: 'ROLE_STAFF',
          title: () => {
            return keysT('keys_createOrganisation');
          },
          hideNavMenuEntry: !env().isKeycloakVisible,
        },
      },
      {
        path: `:memberId`,
        component: ResourceManagementShowOrganization,
        name: 'keymanager.organizations.read',
        props: (route) => {
          const memberId = Array.isArray(route.params.memberId)
            ? route.params.memberId[0]
            : route.params.memberId;

          return {
            memberId: parseInt(memberId || '0'),
          };
        },
        meta: {
          requiresAuth: true,
          // The function checks if 'route.params' is not defined or if 'route.params.memberId' is equal to the placeholder ':memberId'. If either of these conditions are met,
          // the function returns 'false', indicating that the breadcrumb should not be displayed for the current route.
          // If the conditions are not met, the function returns an object with a 'text' property set to 'route.params.memberId', which is the display text for the breadcrumb.
          // This allows the breadcrumb to display the memberId as the breadcrumb text when viewing an organization's details page.
          title: (route: RouteUnion) => {
            if ('params' in route) {
              if (!route.params || route.params?.memberId === ':memberId') {
                return null;
              }
              return route.params?.memberId.toString();
            }
            return null;
          },
          hideNavMenuEntry: true,
          disableBreadcrumb: true,
        },
      },
    ],
  });
}

const developmentRoutes: RouteRecordRaw[] = [];
if (env().isDevMode) {
  const FluentDemo = () => import('@/views/FluentDemo.vue');
  developmentRoutes.push({
    path: '/fluent-demo',
    component: FluentDemo,
    name: 'fluent-demo',
    meta: {
      title: 'Fluent Demo',
      hideBreadcrumb: true,
    },
  });
}

/**
 * Defines the application's routes, including both development and protected routes,
 * with Keycloak authentication, dynamic titles via i18n, and error handling.
 */
export const routes: RouteRecordRaw[] = [
  ...developmentRoutes,
  {
    path: '/login',
    name: RouteNames.login,
    component: AuthenticatorLoginPage,
    props: true,
    meta: {
      hideNavMenuEntry: true,
      requiresAuth: false,
    },
  },
  {
    path: '/',
    component: Index,
    name: 'home',
    meta: {
      hideBreadcrumb: true,
      title: () => {
        return t('app-page-index-title', 'Select a tool');
      },
    },
  },
  ...protectedRoutes,
  {
    path: '/records',
    component: ContentRegistrationHome,
    name: 'contentRegistration.home',
    meta: {
      title: () => {
        return t('record-registration-title');
      },
      hideBreadcrumb: true,
      requiresCDSAuth: true,
    },
    beforeEnter: () => {
      setGetHelpUrl(URL_DOCS_RECORD_MANAGEMENT_TOOL);
    },
  },
  {
    path: '/records/new-record/:recordType?',
    component: ContentRegistrationNewRecord,
    name: 'contentRegistration.newRecord',
    meta: {
      title: () => {
        return t('record-registration-cta');
      },
      hideNavMenuEntry: true,
      hideBreadcrumb: true,
      requiresCDSAuth: true,
    },
    beforeEnter: () => {
      setGetHelpUrl(URL_DOCS_RECORD_MANAGEMENT_TOOL);
    },
  },
  {
    path: '/records/edit/:recordType?/:recordName?',
    component: ContentRegistrationForm,
    name: 'content-registration_edit-record',
    props: true,
    meta: {
      title: () => {
        return t('record-registration-cta');
      },
      hideNavMenuEntry: true,
      hideBreadcrumb: true,
      requiresCDSAuth: true,
    },
    beforeEnter: () => {
      setGetHelpUrl(URL_DOCS_RECORD_MANAGEMENT_TOOL);
    },
  },
  {
    path: '/records/complete',
    component: SubmissionComplete,
    name: 'content-registration.complete',
    props: true,
    meta: {
      title: () => {
        return t(
          'content-registration-submission-complete-title',
          'Submission Complete'
        );
      },
      beforeEnter: () => {
        setGetHelpUrl(URL_DOCS_RECORD_MANAGEMENT_TOOL);
      },
      hideNavMenuEntry: true,
      hideBreadcrumb: true,
      requiresCDSAuth: true,
    },
  },
  // will match everything
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    meta: {
      error: true,
      errorCode: 404,
      hideNavMenuEntry: true,
      hideBreadcrumb: true,
    },
  },
] as RouteRecordRaw[];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

/**
 * Initializes and configures the Vue Router instance with web history mode and
 * application-specific routes. It includes beforeEach guards for setting page titles
 * and handling authentication with Keycloak.
 *
 * @returns The configured Vue Router instance.
 */
export const initializeRouter = () => {
  return router;
};

router.beforeEach(async (to) => {
  setPageTitle(to);
  resetGetHelpUrl();
});

if (env().isKeycloakEnabled) {
  router.beforeEach(async (to) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      try {
        await attemptLogin(to);
      } catch (error) {
        console.error(error);
        return false; // Cancel the navigation
      }
    }
  });
}

router.beforeEach(async (to) => {
  const userStore = useUserStore();
  if (
    // check if the user is already authenticated
    userStore.getUserIfValid() &&
    // is this the login route
    to.name === RouteNames.login
  ) {
    // check for a redirect
    if (typeof to.query.redirect === 'string') {
      // we have likely been returned here after a keycloak forced logout - continue
      return to.query.redirect;
    }
  }
  if (to.matched.some((record) => record.meta.requiresCDSAuth)) {
    if (
      // make sure the user is authenticated
      !userStore.getUserIfValid() &&
      // ❗️ Avoid an infinite redirect
      to.name !== RouteNames.login
    ) {
      const loginPath = to.fullPath;
      // redirect the user to the login page
      router.push({
        name: RouteNames.login,
        query: {
          redirect: loginPath,
        },
      });
    }
  }
});


/**
 * The default export is the Vue Router instance, configured with history and routes, ready
 * for use in a Vue app.
 */
export default router;
