<template>
  <v-fade-transition>
    <component
      v-if="icon"
      :is="component"
      :icon="icon"
      :l10nKey="l10nKey"
      color="primary"
      size="large"
      @executeCallback="emit('executeCallback')"
      data-test-id="toolbar-item"
    ></component>
  </v-fade-transition>
</template>

<script setup lang="ts">
import { useTranslationNamespace } from '@/composable/useI18n';
import componentMap from '@/constants/componentMap';
import { I18nNamespace } from '@/i18n';
import { ToolBarItem } from '@/stores/toolbar';
import { computed, defineAsyncComponent } from 'vue';

const _props = defineProps<ToolBarItem>();
const emit = defineEmits<{
  (event: 'executeCallback', data?: any): void;
}>();

const component = computed(() => {
  if (_props.name) {
    const loadComponent = componentMap[_props.name];
    return defineAsyncComponent(loadComponent);
  }

  // Alternatively, could return a default component here .
  return null;
});

const t = useTranslationNamespace(I18nNamespace.ContentRegistration);
const btnLabel = computed(() => {
  if (_props.l10nKey) {
    return t(_props.l10nKey);
  }
});
</script>
