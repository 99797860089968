// src/composables/useDialog.ts
import { computed } from 'vue';
import { useDialogStore } from '@/stores/dialogs';
import {
  XmlConversionBadInputError,
  DepositSubmissionError,
  DepositSubmissionAuthenticationError,
  XmlConversionNetworkError,
  XmlConversionError,
  DepositSubmissionNetworkError
} from "@/errors";
import ErrorDialogBase from '@/components/ErrorDialogBase.vue';
import ErrorDialogXmlConversionBadDataError from "@/components/ErrorDialogXmlConversionBadDataError.vue";
import ErrorDialogXmlConversionError from "@/components/ErrorDialogXmlConversionError.vue";
import ErrorDialogDepositSubmissionError from "@/components/ErrorDialogDepositSubmissionError.vue";
import ErrorDialogDepositSubmissionAuthenticationError
  from "@/components/ErrorDialogDepositSubmissionAuthenticationError.vue";
import ErrorDialogXmlConversionNetworkError from '@/components/ErrorDialogXmlConversionNetworkError.vue';
import ErrorDialogDepositSubmissionNetworkError from "@/components/ErrorDialogDepositSubmissionNetworkError.vue";

export const useDialog = () => {
  const dialogStore = useDialogStore();

  const dialog = computed(() => dialogStore.dialog);

  const dismissDialog = () => {
    dialogStore.dismissDialog();
  };

  const executeCallback = () => {
    dialogStore.executeCallback();
  };

  const getDialogComponent = (error: Error) => {
    if (error instanceof XmlConversionBadInputError){
      return ErrorDialogXmlConversionBadDataError;
    }
    if (error instanceof XmlConversionError) {
      return ErrorDialogXmlConversionError;
    }
    if (error instanceof XmlConversionNetworkError) {
      return ErrorDialogXmlConversionNetworkError;
    }
    if (error instanceof DepositSubmissionNetworkError) {
      return ErrorDialogDepositSubmissionNetworkError;
    }
    if (error instanceof DepositSubmissionError) {
      return ErrorDialogDepositSubmissionError
    }
    if (error instanceof DepositSubmissionAuthenticationError){
      return ErrorDialogDepositSubmissionAuthenticationError
    }
    return ErrorDialogBase;
  };

  const getDialogTitle = (error: Error) => {
    return 'Error';
  };

  return {
    dialogStore,
    dialog,
    dismissDialog,
    executeCallback,
    getDialogComponent,
    getDialogTitle,
  };
};
