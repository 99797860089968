import { BaseApi } from '@/api/BaseApi';
import { AxiosApi } from '@/api/AxiosApi';

/**
 * Class for API access
 */
export class Api extends BaseApi {
  provider: AxiosApi;
  constructor(baseUrl: string) {
    super(baseUrl);
    this.provider = new AxiosApi(this.baseUrl);
  }

  async fetch(url: string) {
    return await this.provider.fetch(url);
  }

  async post(url: string, data: any, config = {}) {
    return await this.provider.post(url, data, config);
  }

  encodeFormParams(params: any) {
    return Object.keys(params)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      )
      .join('&');
  }
}
