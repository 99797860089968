<template>
  <ErrorDialogBase :dialog="dialog" @dismiss-dialog="emit('dismissDialog')" @execute-callback="emit('executeCallback')">
    <template #title><v-card-title>{{ t('network-error-title') }}</v-card-title></template>
    <template #text>
        <TranslationComponent :translation="t(messageKey)">
          <template #support-email-link>
            <AppLink :to="URL_MAILTO_SUPPORT">{{ EMAIL_SUPPORT }}</AppLink>
          </template>
          <template #status-page-link>
            <AppLink :to="URL_STATUS_PAGE">{{ URL_STATUS_PAGE }}</AppLink>
          </template>
        </TranslationComponent>
        <ErrorDialogRawMessage v-if="isAppError(dialog.error)" :error="dialog?.error"></ErrorDialogRawMessage>
    </template>
    <template #actions>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="emit('dismissDialog')">{{ t('button-close') }}</v-btn>
        <v-btn color="primary" @click="emit('executeCallback')">{{ t('button-try-again') }}</v-btn>
      </v-card-actions>
    </template>
  </ErrorDialogBase>
</template>

<script setup lang="ts">
import {ErrorDialogEmits, ErrorDialogProps, isAppError} from "@/common/types";
import {useDialog} from "@/composable/useDialog";
import ErrorDialogBase from "@/components/ErrorDialogBase.vue";
import {useTranslationNamespace} from "@/composable/useI18n";
import {I18nNamespace} from "@/i18n";
import {URL_MAILTO_SUPPORT, URL_STATUS_PAGE} from "@/constants/urls";
import {EMAIL_SUPPORT} from "@/constants/contact";
import {TranslationComponent} from "i18next-vue";
import AppLink from "@/components/AppLink.vue";
import ErrorDialogRawMessage from "./ErrorDialogRawMessage.vue";

const props = defineProps<ErrorDialogProps>()
const emit = defineEmits<ErrorDialogEmits>()

const t = useTranslationNamespace(I18nNamespace.ContentRegistration);
const messageKey = 'xml_rendering_error_network';

const { getDialogTitle } = useDialog();
defineExpose({ isAppError })
</script>
