<template>
  <v-navigation-drawer v-model="localDrawer" temporary>
    <v-list>
      <v-list-item class="py-6 py-xs-2">
        <router-link :to="{ name: 'home' }" @click="$emit('close')">
          <app-logo-workmark></app-logo-workmark>
        </router-link>
      </v-list-item>
      <v-divider></v-divider>

      <menu-item
        v-for="(item, index) in menuItems"
        :key="index"
        :menu-item="item"
        @close="$emit('close')"
      ></menu-item>
    </v-list>
    <v-divider></v-divider>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { ref, watch, defineEmits } from 'vue';
import AppLogoWorkmark from '@/components/AppLogoWordmark.vue';
import MenuItem from '@/components/MenuItem.vue';
import { useMenuItems } from '@/composable/useMenuItems';

// Emit function to handle custom events
const emit = defineEmits(['close', 'update:modelValue']);

// Props definition
const props = defineProps<{ modelValue: boolean }>();

// Data (ref for local state)
const localDrawer = ref<boolean>(props.modelValue);

// Composition API hooks
const { menuItems } = useMenuItems();

// Watchers
watch(
  () => props.modelValue,
  (newValue) => {
    localDrawer.value = newValue;
  }
);

watch(localDrawer, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>

<style scoped>
</style>
