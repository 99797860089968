<script setup lang="ts">
import { computed, defineProps } from 'vue';
import { RouterLink, type RouterLinkProps } from 'vue-router';

// Define props as simply extending RouterLinkProps.
const props = defineProps<RouterLinkProps>();

const isExternalLink = computed(() => {
  // This regex matches strings that start with a scheme followed by ':', optionally followed by '//' (one or two slashes)
  return typeof props.to === 'string' && /^[a-z]+:\/?\/?/.test(props.to);
});

// Ensures 'to' is a string when used as an href attribute, with a fallback.
const hrefValue = computed(() => {
  return typeof props.to === 'string' ? props.to : '';
});
</script>

<template>
  <!-- Render an <a> tag for external links with all attributes bound from the parent and href set safely -->
  <a v-if="isExternalLink" v-bind="$attrs" :href="hrefValue" target="_blank" rel="noopener noreferrer">
    <slot />
  </a>
  <!-- Use the default router-link for internal navigation, passing all props -->
  <router-link v-else v-bind="$props">
    <slot />
  </router-link>
</template>
