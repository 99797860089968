interface AppErrorOptions {
  code?: number | null;
  domainCode?: string | null;
  domainMessage?: string | null;
}

export class AppError extends Error {
  code: number | null;
  domainCode: string | null;
  domainMessage: string | null;

  constructor(
    message: string,
    {
      code = null,
      domainCode = null,
      domainMessage = null,
    }: AppErrorOptions = {}
  ) {
    super(message);
    this.name = 'AppError';
    this.code = code;
    this.domainCode = domainCode;
    this.domainMessage = domainMessage;
  }
}

export class XmlConversionError extends AppError {
  constructor(message, opts: AppErrorOptions = {}) {
    super(message, opts);
    this.name = 'XmlConversionError';
  }
}

export class XmlConversionNetworkError extends AppError {
  constructor(message, opts: AppErrorOptions = {}) {
    super(message, opts);
    this.name = 'XmlConversionNetworkError';
  }
}

export class XmlConversionBadInputError extends AppError {
  constructor(message, opts: AppErrorOptions = {}) {
    super(message, opts);
    this.name = 'XmlConversionBadInputError';
  }
}

export class DepositSubmissionError extends AppError {
  constructor(message, opts: AppErrorOptions = {}) {
    super(message, opts);
    this.name = 'DepositSubmissionError';
  }
}

export class DepositSubmissionNetworkError extends AppError {
  constructor(message, opts: AppErrorOptions = {}) {
    super(message, opts);
    this.name = 'DepositSubmissionNetworkError';
  }
}

export class DepositSubmissionAuthenticationError extends AppError {
  constructor(message, opts: AppErrorOptions = {}) {
    super(message, opts);
    this.name = 'DepositSubmissionAuthenticationError';
  }
}
