// stores/toolbar.js
import { defineStore } from 'pinia';

export type ToolBarItem = {
  name: string | null;
  icon: string | null;
  l10nKey: string | null;
  callBack: (() => void) | null;
};

export const useToolbarIconStore = defineStore('toolbar', {
  state: (): ToolBarItem => ({
    name: null,
    icon: null,
    l10nKey: null,
    callBack: null,
  }),
  actions: {
    setToolbarIcon(name, icon, l10nKey, callBack) {
      this.name = name;
      this.icon = icon;
      this.l10nKey = l10nKey;
      this.callBack = callBack;
    },
    clearToolbarIcon() {
      this.name = null;
      this.icon = null;
      this.l10nKey = null;
      this.callBack = null;
    },
    executeCallback() {
      if (this.callBack) {
        this.callBack();
      }
    },
  },
});
