import {
  ContentRegistrationFormPayload,
  ContentRegistrationRecord,
} from '@/common/types';
import { defineStore } from 'pinia';

export const useRecordStore = defineStore('records', {
  state: () => ({
    inProgress: null as ContentRegistrationFormPayload | null,
    lastCompleted: null as ContentRegistrationFormPayload | null,
  }),
  actions: {
    moveRecordToCompleted() {
      if (!this.inProgress) return;
      this.$patch({
        lastCompleted: this.inProgress,
        inProgress: null,
      });
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
