import { useHead } from '@unhead/vue';
import { resolveBreadcrumbs, resolvePageTitleFromBreadcrumbs } from './routes';
import { RouteLocationNormalized } from 'vue-router';

const setPageTitle = (to: RouteLocationNormalized) => {
  const title = resolvePageTitleFromBreadcrumbs(
    resolveBreadcrumbs(to.matched, to),
    to,
    'Crossref'
  );
  if (!title) {
    return;
  }
  useHead({
    title: title,
  });
};

export default setPageTitle;
