<template>
  <v-switch
    :model-value="fieldStyleFill === 'outlined'"
    color="primary"
    :label="label"
    @update:model-value="onToggleFieldStyle"
  ></v-switch>
</template>

<script setup lang="ts">
import useFormTheme from '@/composable/useFormTheme';
import { useTranslation } from '@/composable/useI18n';

const t = useTranslation();
const label = t('accessibility_field_style_outline');
const { changeFieldStyleFill, fieldStyleFill } = useFormTheme();

const onToggleFieldStyle = () => {
  const newStyle = fieldStyleFill.value === 'outlined' ? 'filled' : 'outlined';
  changeFieldStyleFill(newStyle);
};
</script>

<style scoped>

</style>
