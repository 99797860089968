// src/stores/dialogs.ts
import { AppError } from '@/errors';
import { defineStore } from 'pinia';

export type Dialog = {
  error: Error | AppError;
  callback: (() => void) | null;
  secondaryActionText?: string | null;
  closeOnCallback: boolean;
};

export const useDialogStore = defineStore('dialogs', {
  state: () => ({
    dialog: null as Dialog | null,
    show: false,
    context: {} as any,
  }),
  actions: {
    showDialog(
      error: Error,
      callback: (() => void) | null = null,
      secondaryActionText: string = 'Action',
      closeOnCallback: boolean = true,
      context?: any
    ) {
      this.dialog = { error, callback, secondaryActionText, closeOnCallback };
      this.context = context;
      this.show = true;
    },
    dismissDialog() {
      this.dialog = null;
      this.show = false;
    },
    executeCallback() {
      if (this.dialog?.callback) {
        this.dialog.callback();
      }
      if (this.dialog?.closeOnCallback) {
        this.dismissDialog();
      }
    },
  },
});
