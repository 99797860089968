<template>
  <v-app-bar flat color="white" class="px-4">
    <a href="/">
      <app-logo-workmark></app-logo-workmark>
    </a>
    <v-spacer />
    <settings v-if="showDevSettingsMenu" />
    <get-help-button :url="appStore.getHelpUrl"></get-help-button>
    <LoginButton :user="userStore.user" @login="showLoginBox" @logout="logoutUser"></LoginButton>
    <TheUserMenu
      v-if="showUserMenu"
      :username="loggedInUsername"
      @LOGOUT_REQUEST="logout"
    />
    <language-menu class="mr-4" />
    <theme-palette v-if="showThemePalette" />
  </v-app-bar>
</template>

<script lang="ts">
/* eslint no-console: ["error", { allow: ["error"] }] */
import { computed, defineComponent } from 'vue';
import LanguageMenu from '@/components/TheLanguageMenu.vue';
import AppLogoWorkmark from '@/components/AppLogoWordmark.vue';
import Settings from '@/modules/DevSettingsModule/Components/Settings.vue';
import ThemePalette from './ThemePalette.vue';
import TheUserMenu from './TheUserMenu.vue';
import { env } from '@/env';
import { useAuth, useUserInfo } from '@/composable/useAuth';
import { useToolbarIconStore } from '@/stores/toolbar';
import ToolbarItem from '@/components/ToolbarItem.vue';
import GetHelpButton from "@/components/GetHelpButton.vue";
import {useAppStore} from "@/stores/app";
import { useAppService } from '@/statemachines/app.machine';
import { useActor } from '@xstate/vue';
import LoginButton from './LoginButton.vue';
import { useUserStore } from '@/stores/user';

export default defineComponent({
  name: 'HeaderBar',
  components: {
    GetHelpButton,
    AppLogoWorkmark,
    LanguageMenu,
    Settings,
    TheUserMenu,
    ThemePalette,
    ToolbarItem,
    LoginButton,
  },
  setup() {
    const appStore = useAppStore();
    const appService = useAppService()
    const credentialsMachine = appService.state.value.context.credentialsMachineRef;
    const credentialsService = useActor(credentialsMachine);
    const { id: loggedInUserId, mail: loggedInUserEmail } = useUserInfo();
    const loggedInUsername = computed(() => {
      return loggedInUserEmail.value ?? loggedInUserId.value;
    });
    const { logout } = useAuth();
    const doLogout = () =>
      logout().catch((error) => {
        console.error('Error calling logout:', error);
      });
    const showUserMenu = computed(() => env().isKeycloakEnabled);
    const showDevSettingsMenu = computed(() => env().isDevSettingsMenu);
    const showThemePalette = computed(() => env().showThemePalette);
    const toolbarIconStore = useToolbarIconStore()
    const userStore = useUserStore();
    const showLoginBox = () => credentialsService.send('SHOW_LOGIN_BOX');
    const logoutUser = () => userStore.logoutUser();


    return {
      appStore,
      loggedInUserEmail,
      loggedInUserId,
      loggedInUsername,
      logout: doLogout,
      showDevSettingsMenu,
      showUserMenu,
      showThemePalette,
      toolbarIconStore,
      credentialsService,
      showLoginBox,
      userStore,
      logoutUser,
    };
  },
});
</script>

<style scoped>

</style>
