import { AppService, machine } from '@/statemachines/app.machine';
import { Ref, ref } from 'vue';
import { interpret } from 'xstate';
import { useInspector } from '@/statemachines/utils';
import { useInterpret } from '@xstate/vue';
import { store } from '@/store';

const serviceInstance: Ref<AppService | null> = ref(null);

export const useAppMachine = () => {
  if (!store.appService) {
    // machine is not instantiated - init
    if (import.meta.env.MODE === 'test') {
      const machineWithConfig = machine.withConfig({}, machine.context || {});
      store.appService = interpret(machineWithConfig, {
        devTools: useInspector(),
      }).start();
    } else {
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      store.appService = useInterpret(machine, {
        devTools: useInspector(),
      });
    }
  }
  return store.appService;
};
