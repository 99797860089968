<template>
  <div data-cy="login-box">
    <keep-alive>
      <component
        :is="currentComponent"
        :loading="loading"
        :username="username"
        :roles="roles"
        :error-message="errorMessage || undefined"
        @authLoginFormSubmit="onAuthLoginFormSubmit"
        @roleSelected="onSelectRole"
        @goBack="onGoBack"
        @restart="send('RESTART')"
        @cancel="onCancel"
      ></component>
    </keep-alive>
  </div>
</template>

<script lang="ts">
/**
 * Handles the capture and submission of user credentials
 *
 * There is a state machine handling the authentication state, which is described in src/machines/authMachine.js
 * The authentication API calls are handled by the Auth library, which is defined in src/api/Auth.js.
 */
import { authMachine } from '@/statemachines/authMachine';
import LoginBoxStepAuthenticate from '@/components/login-box/LoginBoxStepAuthenticate.vue';
import LoginBoxStepSelectRole from '@/components/login-box/LoginBoxStepSelectRole.vue';
import RoleSelectedMessage from '@/components/login-box/RoleSelectedMessage.vue';
import LoginBoxStepNoRolesAssigned from '@/components/login-box/LoginBoxStepNoRolesAssigned.vue';
import { Auth, authBaseConfig } from '@/api/Auth';
import { env } from '@/env';
import { defineComponent } from 'vue';
import { getAuthService } from '@/statemachines/auth.machine';
import { useUserStore } from '@/stores/user';

export default defineComponent({
  name: 'LoginBox',
  components: {
    LoginBoxStepNoRolesAssigned,
    RoleSelectedMessage,
    LoginBoxStepSelectRole,
    LoginBoxStepAuthenticate,
  },
  props: {
    authState: {
      type: Object,
      required: true,
    },
    authSend: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      /** Interpret the machine and store it in data */
      authMachineService: getAuthService(),

      // /** Start with the machine's initial state */
      current: this.authState,

      /** Start with the machine's initial context */
      context: authMachine.context,
      errorMessage: null,
      isLoginFormValid: false,
      username: '',
      password: '',
      role: null,
      roles: [] as any[] | null,
      token: null,
    };
  },
  computed: {
    /**
     * Whether the 'login' button is clickable
     */
    isLoginButtonEnabled(): boolean {
      switch (this.current.value) {
        case 'authenticate':
          return this.isLoginFormValid;
        case 'authenticated.rolesAssigned':
          return this.role !== null;
      }
      return false;
    },
    /**
     * Whether the loading bar should be shown
     */
    loading(): boolean {
      return [
        'authenticating',
        'authenticated.rolesAssigned.authorisingRole',
      ].some(this.current.matches);
    },
    showLoginBoxStepAuthenticate(): boolean {
      return (
        ['unauthenticated', 'authenticating'].some(this.current.matches) ||
        (this.current.matches({
          authenticated: { rolesAssigned: 'authorisingRole' },
        }) &&
          this.roles?.length === 1)
      );
    },
    showLoginBoxStepSelectRole(): boolean {
      return (
        [
          { authenticated: { rolesAssigned: 'idle' } },
          { authenticated: { rolesAssigned: 'authorisingRole' } },
        ].some(this.current.matches) && !this.hasSingleRole
      );
    },
    showLoginBoxStepNoRolesAssigned(): boolean {
      return this.current.matches({ authenticated: 'noRolesAssigned' });
    },
    showRoleSelectedMessage(): boolean {
      return this.current.matches({
        authenticated: { rolesAssigned: 'roleAuthorised' },
      });
    },
    hasSingleRole(): boolean {
      return this.roles?.length === 1;
    },
    currentComponent(): string {
      if (this.showLoginBoxStepSelectRole) return 'LoginBoxStepSelectRole';
      if (this.showLoginBoxStepAuthenticate) return 'LoginBoxStepAuthenticate';
      if (this.showLoginBoxStepNoRolesAssigned)
        return 'LoginBoxStepNoRolesAssigned';
      if (this.showRoleSelectedMessage) return 'RoleSelectedMessage';
      return 'LoginBoxStepAuthenticate';
    },
  },
  created() {
    /**
     * @type {Auth} Create a new instance of the Auth class, passing the app config to it
     */
    (this as any).auth = new Auth(authBaseConfig);
    // Start Auth Machine service on component creation
    this.authMachineService
      .onTransition((state) => {
        // Update the current state component data property with the next state
        this.current = state;
        // Update the context component data property with the updated context
        this.context = state.context;
      })
      .start();
  },
  methods: {
    /** Send events to the authMachine service */
    send(event: any) {
      this.authMachineService.send(event);
    },
    /**
     * Called when a user clicks the 'restart' button
     * Reset the data
     */
    restartLogin() {
      this.username = '';
      this.password = '';
      this.token = null;
      this.roles = null;
      this.isLoginFormValid = false;
      this.send('RESTART');
    },
    /**
     * Called when a user clicks the 'login' button
     *
     * @param payload
     * @returns {Promise<void>}
     */
    async onAuthLoginFormSubmit(payload: any) {
      this.errorMessage = null;
      this.username = payload.username;
      this.send('SEND_CREDENTIALS');
      const loginResponse = await (this as any).auth.login(payload);
      if (loginResponse.authenticated === false) {
        this.errorMessage = loginResponse.errorMessage;
        this.send('AUTH_FAIL');
        return;
      }
      this.onAuthSuccess({
        username: payload.username,
        password: payload.password,
        roles: loginResponse.roles,
        token: loginResponse.token,
      });
    },
    /**
     * Called when the auth API returns a successful response
     * @param payload
     */
    onAuthSuccess(payload: any) {
      this.send('AUTH_SUCCESS');
      this.password = payload.password;
      this.token = payload.token;
      this.onRolesReceived(payload);
    },
    /**
     * Called when the auth API returns an (possibly empty) list of assigned roles
     * @param payload
     */
    onRolesReceived(payload: any) {
      if (Array.isArray(payload.roles) && payload.roles.length < 1) {
        this.send('ROLES_EMPTY');
        return;
      }
      this.send('ROLES_ASSIGNED');
      this.roles = payload.roles;
      // If only one role is returned, automatically select it
      if (payload.roles.length === 1) {
        this.onSelectRole({
          role: payload.roles[0],
        });
      }
    },
    /**
     * Called when a user selects a role and clicks 'continue'
     * @param payload
     * @returns {Promise<void>}
     */
    async onSelectRole(payload: any) {
      this.send('ROLE_SELECTED');
      const authoriseRoleResponse = await this.authoriseRoleSelection(
        payload.role
      );
      if (
        typeof authoriseRoleResponse.errorMessage === 'string' &&
        authoriseRoleResponse.errorMessage.length > 0
      ) {
        this.errorMessage = authoriseRoleResponse.errorMessage;
        this.send('AUTHORISE_FAIL');
        return;
      }
      this.role = payload.role;
      this.onRoleAuthorised(authoriseRoleResponse);
    },
    /**
     * Called when the auth API responds successfully to a role authorisation request
     * @param payload
     */
    onRoleAuthorised(payload: any) {
      this.send('AUTHORISE_SUCCESS');
      this.send('RESTART');
      const userStore = useUserStore();
      userStore.user = {
            usr: payload.username,
            username: payload.username,
            role: payload.role,
            token: payload.token,
            refreshToken: payload.refreshToken,
          };
      const event = new CustomEvent('roleAuthorised', { detail: payload });
      const authRoleAuthorisedEvent = new CustomEvent('authRoleAuthorised', {
        detail: payload,
      });
      const forceKeycloakLogoutEvent = new CustomEvent('forceKeycloakLogout',
        {
          detail: {
            redirectUri: window.location.href
          }
        }
      );
      window.dispatchEvent(event);

      window.dispatchEvent(authRoleAuthorisedEvent);
      window.dispatchEvent(forceKeycloakLogoutEvent);
      if (env().REDIRECT_ON_ROLE_AUTHORISATION) {
        if (payload.redirect) {
          window.location.replace(`${payload.redirect}`);
        } else {
          location.reload();
        }
      }
      this.$emit('success')
      this.$emit('loginScreenDeactivate');
    },
    /**
     * Calls the auth API to request a user assume a particular role
     * @param role
     * @returns {Promise<*>}
     */
    async authoriseRoleSelection(role: any) {
      const payload = {
        username: this.username,
        password: this.password,
        role: role,
      };
      const config = {
      };
      const response = await (this as any).auth.authoriseRole(payload, config);
      return response;
    },
    /**
     * Called when a user clicks the 'Back' button
     */
    onGoBack() {
      this.errorMessage = null;
      this.send('GO_BACK');
    },
    /**
     * Called when a user clicks the 'Cancel' or button
     */
    onCancel() {
      this.$emit('cancel')
      // window.dispatchEvent(new CustomEvent('authCancel'));
    },
  },
});
</script>

<style scoped>

</style>
