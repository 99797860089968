import {
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  RouteRecordNormalized,
  RouteRecordRaw,
} from 'vue-router';
import {useToolbarIconStore} from "@/stores/toolbar";
import {ComponentNames} from "@/constants/componentNames";
import {Icons} from "@/constants/icons";
import {useAppStore} from "@/stores/app";
import {URL_DOCS_RECORD_MANAGEMENT_TOOL, URL_DOCS_ROOT} from "@/constants/urls";

export type Breadcrumb = {
  title: string;
  href?: string;
  disabled?: boolean;
  hide?: boolean;
};

export function resolveBreadcrumbs(
  matchedRoutes: RouteRecordNormalized[],
  route: RouteLocationNormalizedLoaded
): Breadcrumb[] {
  const initialBreadcrumbs = matchedRoutes
    .map((matchedRoute) => {
      const breadcrumb = matchedRoute.meta?.title;
      const hideBreadcrumb = matchedRoute.meta?.hideBreadcrumb ?? false;

      if (!breadcrumb) {
        return null;
      }

      const resolvedBreadcrumb = resolveTitle(matchedRoute, route);

      if (resolvedBreadcrumb === null) {
        return null;
      }
      const disabled = matchedRoute.meta?.disableBreadcrumb || false;
      return {
        title: resolvedBreadcrumb,
        disabled: disabled,
        href: matchedRoute.path,
        hide: hideBreadcrumb,
      } as Breadcrumb;
    })
    .filter((item): item is Breadcrumb => item !== null);

  return initialBreadcrumbs;
}

export function resolveTitle(
  route: RouteRecordNormalized | RouteRecordRaw,
  fallbackRoute:
    | RouteRecordNormalized
    | RouteLocationNormalizedLoaded
    | null = null
) {
  const title = route?.meta?.title;
  if (typeof title === 'string') return title;
  if (typeof title === 'function') {
    const result = title(fallbackRoute || route);
    if (typeof result === 'string') return result;
  }
  return '';
}

export function resolvePageTitleFromBreadcrumbs(
  breadcrumbs: Breadcrumb[],
  route: RouteLocationNormalized,
  defaultTitle: string
): string {
  if (breadcrumbs.length === 0) {
    return route.name?.toString() ?? defaultTitle;
  }

  const titles = breadcrumbs.map((breadcrumb) => breadcrumb.title);
  const reversedTitles = titles.reverse().join(' - ');

  if (!reversedTitles) return route.name?.toString() ?? defaultTitle;

  return reversedTitles;
}

export const resetGetHelpUrl = () => {
  const appStore = useAppStore();
  appStore.resetGetHelpUrl();
}

export const setGetHelpUrl = (url = URL_DOCS_ROOT) => {
  const appStore = useAppStore();
  appStore.getHelpUrl = url;
}
