// from https://github.com/usersnap/public/blob/vue-js/vue-js/src/components/Usersnap.vue

<script lang="ts">
import { env } from '@/env';
import { defineComponent } from 'vue';

const USERSNAP_API_KEY = env().USERSNAP_API_KEY;
const USERSNAP_GLOBAL_API_KEY = env().USERSNAP_GLOBAL_API_KEY;

declare global {
  interface Window {
    onUsersnapCXLoad: any;
  }
}

export default defineComponent({
  name: 'Usersnap',
  created() {
    window.onUsersnapCXLoad = function (api: any) {
      api.init();
      api.show(USERSNAP_API_KEY);
    };
    const script = document.createElement('script');
    script.defer = true;
    script.src = `https://widget.usersnap.com/global/load/${USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad`;
    document.getElementsByTagName('head')[0].appendChild(script);
  },
});
</script>

// Include an 'empty' template, to stop vue warnings about no template or render function defined
<template>
  <span />
</template>
