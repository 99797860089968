<template>
  <LoginBoxCard>
    <template #title>{{ $t('login_succeeded_no_roles_assigned') }}</template>
    <template #text>
      <BaseInputUsername :model-value="username" :disabled="true" />
      There are no roles linked to this account.
      <v-spacer />
      Contact Crossref support team at
      <a :href="supportMailto">{{ $t('customer_support_email_address') }}</a>
      or open the support contact form.
    </template>
    <template #actions>
      <v-btn type="button" color="primary" :href="supportUrl" target="_new"
        >Contact support</v-btn
      >
      <!--
      Triggered when the restart button is clicked
      @event restartLogin
      -->
      <v-btn variant="text" color="secondary" @click="restartLogin"
        >Restart</v-btn
      >
    </template>
  </LoginBoxCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
/**
 * Informational card displayed when a user has no roles assigned to their account
 */
import LoginBoxCard from '@/components/login-box/LoginBoxCard.vue';
import BaseInputUsername from '@/components/base/BaseInputUsername.vue';
export default defineComponent({
  name: 'LoginBoxStepNoRolesAssigned',
  components: { BaseInputUsername, LoginBoxCard },
  props: {
    username: {
      type: String,
      required: true,
    },
  },
  computed: {
    /**
     * The Crossref Support email address
     */
    supportMailto(): string {
      // FIXME: convert to composition API
      const supportEmail = this.$t('customer_support_email_address');
      return `mailto:${supportEmail}`;
    },
    supportUrl(): string {
      return this.$t('customer_support_contact_url');
    },
  },
  methods: {
    restartLogin() {
      /**
       * Restart login event
       */
      this.$emit('restartLogin');
    },
  },
});
</script>

<style lang="sass">
//
</style>
