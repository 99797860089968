<template>
  <LoginBoxCard>
    <template #text>
      <AlertError :error-message="errorMessage" />
      <AlertSuccess message="You have selected a role" />
      <p>You have selected the role: {{ role }}</p>
    </template>
    <template #actions>
      <v-btn
        variant="outlined"
        color="primary"
        @click.prevent="$emit('restart')"
      >
        Restart
      </v-btn>
    </template>
  </LoginBoxCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
/**
 * Informational card diplayed when a user has successfully assumed a role
 */
import LoginBoxCard from '@/components/login-box/LoginBoxCard.vue';
import AlertSuccess from '@/components/login-box/AlertSuccess.vue';
import AlertError from '@/components/login-box/AlertError.vue';
export default defineComponent({
  name: 'RoleSelectedMessage',
  components: { AlertSuccess, LoginBoxCard, AlertError },
  props: {
    role: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
});
</script>

<style scoped>

</style>
