<template>
  <div>
    <slot>
      <v-img
        src="https://pbs.twimg.com/profile_images/1202973071604367366/72Ft7ljG.jpg"
        max-width="100"
        contain
        align="left"
      />
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * Renders the crossref logo
 * TODO: see if this can be replaced with BaseCrossrefLogoStacked
 */
export default defineComponent({
  name: 'LoginBoxCardLogo',
});
</script>

<style scoped>

</style>
