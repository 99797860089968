export enum FunderType {
  Org = 'org',
}

export enum AwardType {
  Grant = 'grant',
};

export interface FundingAwardData {
  awardNumber?: string;
  'funder-id'?: string;
  'funder-name'?: string;
}

export class FundingAward {
  readonly awardNumber?: string | null;
  readonly funderId?: string | null;
  readonly funderName?: string | null;

  constructor(
    awardNumber: string | null = null,
    funderId: string | null = null,
    funderName: string | null = null
  ) {
    if (awardNumber) this.awardNumber = awardNumber;
    if (funderId) this.funderId = funderId;
    if (funderName) this.funderName = funderName;
  }

  isEmpty(): boolean {
    return !this.awardNumber && !this.funderId && !this.funderName;
  }
}
