import { resolveTitle } from './../utils/routes';
// src/composable/useMainSectionTitle.ts
import { computed, ComputedRef } from 'vue';
import { useRoute } from 'vue-router';

export function useMainSectionTitle(): {
  mainSectionTitle: ComputedRef<string | null>;
} {
  const route = useRoute();

  const mainSectionTitle = computed(() => {
    const matchedRoute = route.matched?.[0]; // Get the highest parent route that matches
    const r = resolveTitle(matchedRoute);
    return r;
  });

  return { mainSectionTitle };
}
