<template>
  <LoginBoxCard :loading="loading" data-cy="loginStepSelectRole">
    <template #title>Crossref Login</template>
    <template #body>
      <v-form @submit.prevent="onSubmit">
        <v-card-text>
          <AlertError :error-message="errorMessage" />
          <v-row>
            <v-col cols="12">
              <BaseInputUsername :model-value="username" :disabled="true" />
              <LoginBoxInputSelectRole
                :model-value="username"
                :roles="roles"
                @input="role = $event"
              ></LoginBoxInputSelectRole>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row>
              <v-col class="text-left">
                <LoginBoxActionButtonBack @goBack="onGoBack" />
              </v-col>
              <v-col class="text-right">
                <LoginBoxActionButtonLogin>Continue</LoginBoxActionButtonLogin>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-form>
    </template>
  </LoginBoxCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
/**
 * Presents a form with the list of roles assigned to a user, and captures the user's choice of a role to assume.
 */

import BaseInputUsername from '@/components/base/BaseInputUsername.vue';
import LoginBoxInputSelectRole from '@/components/login-box/LoginBoxInputSelectRole.vue';
import LoginBoxCard from '@/components/login-box/LoginBoxCard.vue';
import LoginBoxActionButtonBack from '@/components/login-box/LoginBoxActionButtonBack.vue';
import AlertError from '@/components/login-box/AlertError.vue';
import LoginBoxActionButtonLogin from '@/components/login-box/LoginBoxActionButtonLogin.vue';

export default defineComponent({
  name: 'LoginBoxStepSelectRole',
  components: {
    LoginBoxActionButtonLogin,
    AlertError,
    LoginBoxActionButtonBack,
    LoginBoxCard,
    BaseInputUsername,
    LoginBoxInputSelectRole,
  },
  props: {
    /**
     * Whether the loading bar should be displayed
     */
    loading: {
      type: Boolean,
      required: true,
    },
    /**
     * The username
     */
    username: {
      type: String,
      required: true,
    },
    /**
     * The list of roles assigned to the user
     */
    roles: {
      type: Array,
      required: true,
    },
    /**
     * The error message to display, if any
     */
    errorMessage: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      /**
       * The selected role
       */
      role: null,
      /**
       * Whether to show the extended help text about user roles
       */
      showTooltip: true,
    };
  },
  methods: {
    /**
     * Called when the back button is clicked
     */
    onGoBack() {
      /**
       * Go back event
       */
      this.$emit('goBack');
    },
    /**
     * Called when the continue button is clicked
     */
    onSubmit() {
      const payload = {
        role: this.role,
      };
      /**
       * The role selected event
       * @property role {object} the selected role
       */
      this.$emit('roleSelected', payload);
    },
  },
});
</script>

<style scoped>

</style>
