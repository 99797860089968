<template>
  <notification-snackbar
    v-if="message"
    :message="message"
    :level="level"
    :notification="notification"
    :active="hasActiveNotification"
    :auto-dismiss="autoDismiss"
    @dismiss-notification="onDismissNotification"
  />
</template>

<script lang="ts">
/**
 * Handles the display and dismissal of notifications
 * Attaches to the Notification state machine
 */
import { AlertWithId } from '@/common/types';
import { useAppService } from '@/statemachines/app.machine';
import { defineComponent, computed } from 'vue';
import { useActor } from '@xstate/vue';
import NotificationSnackbar from './NotificationSnackbar.vue';

export default defineComponent({
  name: 'NotificationSnackbarWrapper',
  components: { NotificationSnackbar },
  setup() {
    /**
     * Inject the App state machine service
     */
    const appService = useAppService();
    /**
     * Connect to the Notifications state machine
     */
    const ns = useActor(appService.state.value.children.notificationsMachine);
    /**
     * Send a dissmiss event to the Notifications state machine
     * @param notification AlertWithId
     */
    const dissmissHandler = (notification: AlertWithId) =>
      ns.send({ type: 'DISMISS', notification: notification });
    const dismissCurrentNotification = () => {
      dissmissHandler(notification.value);
    };
    const onDismissNotification = () => {
      dismissCurrentNotification();
    };
    /**
     * Retrieve the current notification from the Notification state machine's extended context
     */
    const notification = computed<AlertWithId>(
      () => ns.state.value.context.currentNotification
    );
    /**
     * Retrieve the autoDismiss value from the state machine's extended context
     */
    const autoDismiss = computed<boolean>(
      () => ns.state.value.context.autoDismissNotifications
    );
    /**
     * Determine whether there is an active notification by matching the relevant state on the Notification state machine
     */
    const hasActiveNotification = computed(() =>
      ns.state.value.matches('displayingNotification')
    );
    /**
     * get the notification message
     */
    const message = computed(() => notification.value?.msg);
    /**
     * get the notification severity
     */
    const level = computed(() => notification.value?.level);
    return {
      autoDismiss,
      hasActiveNotification,
      notification,
      dissmissHandler,
      dismissCurrentNotification,
      ns,
      onDismissNotification,
      message,
      level,
    };
  },
});
</script>

<style scoped></style>
