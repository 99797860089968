/**
 * Utility functions to assist in the mapping of form data to `ItemTree`.
 */

import {
  extractWithDefault,
  getOrNull,
  hasTruthyOrNonEmpty,
} from '@/utils/helpers';
import { DOI_DOT_ORG_PREFIX } from '@/constants/urls';
import { Identifier } from '@/itemTree/Identifier';
import { Properties } from '@/itemTree/Properties';
import { RelationshipType } from '@/itemTree/RelationshipType';
import { Relationship } from '@/itemTree/Relationships';
import {
  createAncestorRelationship,
  createContributorRelationship,
  createFundingAwardRelationship,
  createIssnRelationship,
  createLicenseRelationship,
  createObjectRelationship,
} from '@/itemTree/mapping/relationshipHelpers';
import { Contributor, ContributorData } from '@/models/Contributor';
import { Affiliation } from '@/models/Affiliation';
import { Item } from '@/itemTree/Item';
import { WebResource } from '@/models/WebResource';
import {
  ArticleDataPath,
  JournalDataPath,
} from '@/itemTree/mapping/JournalArticleMapper';
import { FundingAward, FundingAwardData } from '@/models/FundingAward';
import { FormData } from '@/forms/types';
import { Issn, IssnType, ItemTreeIssnType } from '@/models/Issn';
import { DoiData } from '@/models/DoiData';
import { JournalMetadata } from '@/models/JournalMetadata';
import { Abstract, AbstractData } from '@/models/Abstract';

/**
 * Extracts journal titles from form data.
 * @param formData - The source form data.
 * @returns The journal titles if present; otherwise, `null`.
 */
function getJournalTitles(formData) {
  return getOrNull<any>(formData, JournalDataPath.Titles);
}

/**
 * Retrieves the full title of a journal from form data.
 * @param formData - The source form data.
 * @returns The full title if available; otherwise, `null`.
 */
function getJournalFullTitle(formData) {
  return getOrNull<string>(formData, JournalDataPath.FullTitle);
}

/**
 * Retrieves the abbreviated title of a journal from form data.
 * @param formData - The source form data.
 * @returns The abbreviated title if available; otherwise, `null`.
 */
function getJournalAbbrevTitle(formData) {
  return getOrNull<string>(formData, JournalDataPath.AbbrevTitle);
}

/**
 * Placeholder for retrieving the journal language from form data. Not implemented.
 * @param formData - The source form data.
 * @returns `null` as it is not implemented.
 */
function getJournalLanguage(formData) {
  // not implemented in the form
  return null;
}

/**
 * Extracts the DOI of a journal from form data.
 * @param formData - The source form data.
 * @returns The DOI if available; otherwise, `null`.
 */
function getJournalDoi(formData) {
  return getOrNull<string>(formData, JournalDataPath.Doi);
}

/**
 * Retrieves the resource URL of a journal from form data.
 * @param formData - The source form data.
 * @returns The resource URL if available; otherwise, `null`.
 */
function getJournalResourceUrl(formData) {
  return getOrNull<string>(formData, JournalDataPath.ResourceUrl);
}

/**
 * Retrieves the ISSN designated for print from form data and creates an `Issn` instance.
 * @param formData - The source form data.
 * @returns An `Issn` instance for the print ISSN if available; otherwise, `null`.
 */
function getJournalPrintIssn(formData) {
  const issn = getOrNull<string>(formData, JournalDataPath.PrintIssn);
  return issn ? new Issn(IssnType.Print, issn) : null;
}

/**
 * Retrieves the ISSN designated for online publication from form data and creates an `Issn` instance.
 * @param formData - The source form data.
 * @returns An `Issn` instance for the online ISSN if available; otherwise, `null`.
 */
function getJournalOnlineIssn(formData) {
  const issn = getOrNull<string>(formData, JournalDataPath.OnlineIssn);
  return issn ? new Issn(IssnType.Online, issn) : null;
}

/**
 * Generates ISSN relationships for both print and online ISSN from form data.
 * @param formData - The source form data.
 * @returns An array of `Relationship` objects for ISSN.
 */
function getJournalIssnRelationships(formData) {
  const printIssn = getJournalPrintIssn(formData);
  const onlineIssn = getJournalOnlineIssn(formData);

  const rels: any[] = [];

  if (printIssn) {
    rels.push(createIssnRelationship(printIssn));
  }

  if (onlineIssn) {
    rels.push(createIssnRelationship(onlineIssn));
  }

  return rels;
}

/**
 * Constructs a `DoiData` object from journal DOI and resource URL found in form data.
 * @param formData - The source form data.
 * @returns A `DoiData` instance if DOI is present; otherwise, `null`.
 */
export const getJournalDoiData = (formData): DoiData | null => {
  const doi = getJournalDoi(formData);
  const resourceUrl = getJournalResourceUrl(formData);
  return doi ? new DoiData(doi, resourceUrl) : null;
};

/**
 * Creates a `JournalMetadata` instance based on form data if any relevant data is present.
 * @param formData - The source form data.
 * @returns A `JournalMetadata` instance if any relevant data is found; otherwise, `null`.
 */
export const getJournalMetadata = (formData): JournalMetadata | null => {
  const doiData = getJournalDoiData(formData);
  const fullTitle = getJournalFullTitle(formData);
  const abbrevTitle = getJournalAbbrevTitle(formData);
  const language = getJournalLanguage(formData);
  // if there is any truthy Journal Metadata, return a JournalMetadata object, otherwise null
  return [doiData, fullTitle, abbrevTitle, language].some((value) => {
    // is the value a non-zero length array or a truthy primitive?
    return Array.isArray(value) ? value.length > 0 : !!value;
  })
    ? new JournalMetadata(
        getJournalLanguage(formData),
        getJournalFullTitle(formData),
        getJournalAbbrevTitle(formData),
        getJournalDoiData(formData)
      )
    : null;
};

/**
 * Constructs ancestor relationships based on journal metadata and ISSN relationships from form data.
 * @param formData - The source form data.
 * @returns An array of `Relationship` objects for ancestor relationships.
 */
export function getAncestorRelationship(formData): Relationship[] {
  const journalMetadata = getJournalMetadata(formData);
  const issnRels = getJournalIssnRelationships(formData);
  // const resourceResolutionsRel = journalMetadata?.doiData?.doi ?

  if (!hasTruthyOrNonEmpty([journalMetadata, issnRels])) return [];

  return [createAncestorRelationship(journalMetadata, issnRels)];
}

/**
 * Parses a date string into year, month, and day parts.
 * @param date - The date string in 'YYYY-MM-DD' format.
 * @returns A tuple containing the year, month, and day as numbers.
 */
export const parseDateToParts = (date: string): [number, number, number] => {
  return date.split('-').map((part) => parseInt(part, 10)) as [
    number,
    number,
    number
  ];
};

/**
 * Parses a date string to an object containing date parts, a complete date-time string in UTC, and a timestamp.
 * Useful for conversions to item tree where specific date formats are required.
 * @param date - The date string in 'YYYY-MM-DD' format.
 * @returns An object containing parsed date parts, date-time in UTC format, and the timestamp.
 */
export const getDateParts = (date) => {
  /**
   * I'm assuming dates are treated as UTC, given the examples in the REST API test suite
   * specify :date-time "XXXX-XX-XXT00:00:00.000Z"
   **/
  const dateStringUTC = `${date}T00:00:00.000Z`;
  const dateUTC = new Date(`${date}T00:00:00.000Z`);
  return {
    'date-parts': parseDateToParts(date),
    'date-time': dateStringUTC,
    timestamp: dateUTC.getTime(),
    source: 'date-parts',
  };
};

/**
 * Retrieves the published online date from form data and formats it for item tree.
 * @param formData - The source form data.
 * @returns A formatted representation of the online publication date if available; otherwise, `null`.
 */
export const getDatePublishedOnline = (formData) => {
  const date = getOrNull<string>(formData, ArticleDataPath.PublishedOnline);

  const values = {};

  return date ? [getDateParts(date)] : null;
};

/**
 * Retrieves the published in print date from form data and formats it for item tree.
 * @param formData - The source form data.
 * @returns A formatted representation of the print publication date if available; otherwise, `null`.
 */
export const getDatePublishedInPrint = (formData) => {
  const date = getOrNull<string>(formData, ArticleDataPath.PublishedInPrint);

  const values = {};

  return date ? [getDateParts(date)] : null;
};

/**
 * Retrieves publication dates (online and in print) from form data and constructs their representations.
 * @param formData - The source form data.
 * @returns An object with 'published-online' and 'published-print' date representations.
 */
export const getPublicationDates = (formData: any) => {
  const publishedOnlineDate = getDatePublishedOnline(formData);
  const publishedInPrintDate = getDatePublishedInPrint(formData);

  const values = {
    'published-online': publishedOnlineDate,
    'published-print': publishedInPrintDate,
  };

  return values;
};

/**
 * Constructs a resource URL relationship for a Journal Article.
 * @param formData - The source form data.
 * @returns An array containing a `Relationship` object for the resource URL if present; otherwise, an empty array.
 */
export const getArticleResourceUrlRelationship = (formData: any) => {
  const resourceUrl = getOrNull<string>(formData, ArticleDataPath.ResourceUrl);
  if (!resourceUrl) return [];
  const ids = [new Identifier(resourceUrl)];
  const props = [
    new Properties({
      type: 'resolution',
      subtype: 'primary',
    }),
  ];
  const rels = [createObjectRelationship(new WebResource(resourceUrl))];
  const obj = new Item([], props, rels);
  return [new Relationship(RelationshipType.ResourceResolution, obj)];
};

export const getContributors = (formData) => {
  return extractWithDefault<ContributorData[]>(
    ArticleDataPath.Contributors,
    formData,
    []
  );
};

export const getFundingAwards = (formData) => {
  return extractWithDefault<FundingAwardData[]>(
    ArticleDataPath.FundingAwards,
    formData,
    []
  );
};

/**
 * Retrieves license information from form data and constructs a relationship for it.
 * @param form - The source form data.
 * @returns An array containing a `Relationship` object for the license URL if present; otherwise, an empty array.
 */
export const getLicenseRels = (form): Relationship[] | [] => {
  const licenseUrl = getOrNull<string>(form, ArticleDataPath.License);
  return !licenseUrl ? [] : [createLicenseRelationship(licenseUrl)];
};

/**
 * Extracts funding awards information from form data and creates relationships for each.
 * @param formData - The source form data.
 * @returns An array of `Relationship` objects for funding awards.
 */
export function getFundingRelationships(formData: FormData) {
  const awards: FundingAward[] = getFundingAwards(formData).map(
    (data: FundingAwardData) =>
      new FundingAward(
        data?.awardNumber,
        data?.['funder-id'],
        data?.['funder-name']
      )
  );
  const relationships: Relationship[] = [];
  awards.forEach((award) => {
    relationships.push(createFundingAwardRelationship(award));
  });

  return relationships;
}

/**
 * Extracts contributor information from form data and creates relationships for each.
 * @param formData - The source form data.
 * @returns An array of `Relationship` objects for contributors.
 */
export const getContributorRelationships = (formData: any) => {
  const contributors: Contributor[] = getContributors(formData).map(
    (data) =>
      new Contributor(
        data?.givenName,
        data?.familyName,
        data?.suffix,
        new Affiliation(data?.affiliation?.institution, data?.affiliation?.ror),
        data?.orcid,
        data?.role,
        data?.sequence
      )
  );
  const relationships: Relationship[] = [];
  contributors.forEach((contributor) => {
    relationships.push(createContributorRelationship(contributor));
  });

  return relationships;
};

/**
 * Retrieves the DOI for an article from form data.
 * @param formData - The source form data.
 * @returns The article DOI if available; otherwise, `null`.
 */
export const getArticleDoi = (formData) => {
  return getOrNull<string>(formData, ArticleDataPath.DOI);
};

export function transformIdentifiers(formData: any): Identifier[] {
  const doi = getOrNull<string>(formData, 'journal.doi');
  return doi ? [new Identifier(`${DOI_DOT_ORG_PREFIX}${doi}`)] : [];
}

/**
 * Wraps text in <jats:p> tags if it isn't already wrapped.
 * @param text - The text to wrap.
 * @returns The wrapped or original text.
 */
export const wrapWithJatsTags = (text: string): string => {
  if (!text.trim().startsWith('<jats:p>')) {
    return `<jats:p>${text}</jats:p>`;
  }
  return text;
};

/**
 * Extracts and transforms abstract information from form data.
 * @param formData - The source form data.
 * @returns An array of objects representing the abstract(s), including language and value.
 */
export const getAbstract = (formData: any): any[] => {
  const abstracts = extractWithDefault<AbstractData[]>(
    ArticleDataPath.Abstract,
    formData,
    []
  );
  return abstracts
    .map((abstract) => {
      // Ensure undefined is converted to null
      const value = abstract?.abstract ?? null;
      const language = abstract?.language ?? null;
      return Abstract.create(value, language);
    })
    .filter((abstract) => abstract !== null); // Filter out null entries;
};

/**
 * Extracts and formats the full Article title from form data.
 * @param formData - The source form data.
 * @returns An array containing the full title if available; otherwise, an empty array.
 */
export const getFullTitle = (formData: any): any[] => {
  const fullTitle = extractWithDefault<string>('article.title', formData, '');
  return fullTitle ? [{ language: null, value: fullTitle }] : [];
};
