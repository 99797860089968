import {ContentRegistrationType, UserInfo, XMLGenerationStrategy} from '@/common/types';
import { GrantXmlRenderingStrategy } from '@/services/xmlRendering/GrantXmlRenderingStrategy';
import { FormDataContext } from '@/statemachines/form-data.machine';
import { NotImplementedRenderingStrategy } from '@/services/xmlRendering/NotImplementedRenderingStrategy';
import {JournalArticleXmlRenderingStrategy} from "@/services/xmlRendering/JournalArticleXmlRenderingStrategy";

/**
 * Provides functionality for XML generation based on form data context.
 * It selects an XML generation strategy according to the content registration type
 * and generates XML accordingly. Supports pretty printing of the generated XML.
 *
 * This service leverages specific strategies for different types of content registration,
 * like grants or journal articles, to customize the XML output. If a content registration type
 * is not supported, it throws an error indicating an unknown record type.
 *
 * Usage involves providing the form data context and an optional flag for pretty printing.
 * The service then uses the appropriate strategy to generate and return the XML as a string.
 *
 * Example usage:
 * ```
 * const xmlString = await XMLRenderingService.generateXML(formDataContext, true);
 * ```
 *
 * @remarks
 * - Throws an error if the content registration type is unsupported.
 *
 * @param context - The form data context, containing the record type and associated data.
 * @param prettyPrint - Optional. A boolean flag indicating whether the XML should be pretty-printed. Defaults to false.
 * @returns A promise that resolves to a string containing the generated XML.
 */
export class XMLRenderingService {
  static async generateXML(
    context: FormDataContext,
    prettyPrint = false,
    userInfo?: UserInfo
): Promise<string> {
    let strategy: XMLGenerationStrategy;

    switch (context.currentRecord.recordType) {
      case ContentRegistrationType.Grant:
        strategy = new GrantXmlRenderingStrategy();
        break;
      case ContentRegistrationType.JournalArticle:
        strategy = new JournalArticleXmlRenderingStrategy();
        break;
      default:
        throw new Error('Unknown record type');
    }

    return strategy.generateXML(
      context.currentRecord.recordType,
      context.formData || {},
      prettyPrint,
      userInfo
    );
  }
}
