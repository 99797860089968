// @ts-nocheck

import { InjectionKey, provide, inject } from 'vue';
import {
  InterpreterFrom,
  interpret,
  createMachine,
  assign,
  Sender,
} from 'xstate';
import { useActor, useInterpret, useSelector } from '@xstate/vue';
import { useInspector } from '@/statemachines/utils';

import { fetchLoginState } from '@/utils/fetchers';

export interface userProvidedCredentials {
  username: string;
}

export interface UserDetails {
  username: string;
}

export const authenticationMachine = createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QEMCuAXAFgWWQY0wEsA7MAYgCUBRAZQBUBBCugbQAYBdRUABwHtYhdIT7FuIAB6IALACYANCACeiAJwB2WQDppbWQGY2ANiMAOU7ICscgL43FaLLgIkwW1MUeYwxYXmTokGQ0VAByACIA+gDC1OFhdACSDAAyNOxcSCD8gsKi4lIIAIz66tJabEWmVUay0kWyqpaKKghWplpVleZFJZaWRZZ2Dhg4+ESkWl4+fgEkUGQMAKp0ABKRNEvR0bTpnOI5QiJiWYUNLYgNwyBezhNu076E-sLEC8trkQBiDIkpGQcBEd8qdLqVyt0anUGk0Lm02KotEYmup9AMEZZVGZZNdbuNXFNRjNngFIFpCBAADbkCgAeRStEiVGwAAU6ABNAFZQ55E6gM7qNhIyzmSxGNimVGmSoKZSIWTIrTqPr6VSmVQaxr6XGjO4Ex6zQIQclUmn0xkMGg0RIAcVCVHCXN4QN5BUuguFovFkv00vOcoQ+lkQuD+iKbHRCNRBh1TnxkwNJKNWgATnxqbAGLBBFBSBAyDbaZEAEIMaIAaSd2RdxzdxTY9U66g0vWk6ksUvUcPURiKWlUDUFRTbRnqGljYxcCaJTxeZLTGazObzJuplHNGyoDOidAdVZ5tdBbUGntM0mkRn6mjFRjhRlKFQspn0hk0bbbE7106wxLnxoXcBLoQuZkhSa6FiWZaVvs3I1iC-KXA2fZFM2yrDu2nZwtU2i6LIWpoiUdTavYNy6vGDwzoa87poB2bASuXh8CmhCCG8FA0YsKyrLSFCJCEGxbDsVr7nBfKSIgHZCiUzYaHh6HSPo3aWEKA4Kj0qrSOq0ifuRhI-rOpL-jRmZ0SBxqMcxrFQOxa4fNxvH8T8fwibkh4IQgkmdKUGqaLI8mKQG95CiYzZBn5shPjpU4UfpVFGYupkrgBDCjExLFBCktKFisLnAmJZxIU2LboR2vpdgGwZ9nh6jqpepTmPoZh2CRxB8BAcDiHi0WAq58HiQgAC0jVwgN7Y6KYYqCmwaKqMYeFRfc7ieJRSaQD1eV1r0TQVJY+j1EOAyjs0FXDkqZTttYEWXlYxEjHG0V6d4BmvFA62ukeRjla0DS7Vo5gmM+mm9DVpgLfqK1-m9bn9cNAZVNo1W1btINotpJFdYtiZ-quYBQ31hSSnCsg9kiEUXmUL4KSYaN3ZOmMQ4ZWitTZtHLmtsG9fllz+q0ynaMixMGKOGi+kUYPfk9cWpsZQFmXjXMIGecLWPonQWBFopsDV83o2RD1Y4zAEmWzxpgbjHMbUehF3gp-YaD2qjSLJZiqOLMWS6t8Ws-RZIWSx8ws-Lda4V5JhNDNl4IregUPiKEWYmU95ojiuv3fTsWe9LCUm1nYApVgaWwOzzqc3WMk7Zon1OxFCKmEpfb1MTw7VJTGrNTYQA */
  id: 'authMachine',
  initial: 'unauthenticated',
  on: {
    RESTART: {
      target: '#unauthenticated',
      actions: [
        () => {
          console.debug('Restarting Auth Machine');
        },
      ],
    },
  },
  states: {
    unauthenticated: {
      id: 'unauthenticated',
      on: {
        SEND_CREDENTIALS: 'authenticating',
      },
    },
    authenticating: {
      on: {
        AUTH_SUCCESS: 'authenticated',
        AUTH_FAIL: 'unauthenticated',
      },
    },
    authenticated: {
      id: 'authenticated',
      initial: 'idle',
      states: {
        idle: {
          on: {
            ROLES_EMPTY: 'noRolesAssigned',
            ROLES_ASSIGNED: 'rolesAssigned',
          },
        },

        noRolesAssigned: {
          on: {},
        },

        rolesAssigned: {
          id: 'rolesAssigned',
          initial: 'idle',
          states: {
            idle: {
              on: {
                ROLE_SELECTED: '#rolesAssigned.authorisingRole',
                GO_BACK: '#unauthenticated',
              },
            },
            authorisingRole: {
              on: {
                AUTHORISE_SUCCESS: 'roleAuthorised',
                AUTHORISE_FAIL: '#authenticated.rolesAssigned',
              },
            },
            roleAuthorised: {
              on: {
                LOGOUT: '#unauthenticated',
              },
            },
          },
          on: {
            GO_BACK: '#unauthenticated',
          },
        },
      },
    },
  },
});

export type AuthService = InterpreterFrom<typeof authenticationMachine>;
export const authSymbol: InjectionKey<AuthService> = Symbol(
  'AuthServiceInjectionKey'
);

export function getAuthSservice() {
  if (process.env.NODE_ENV === 'test') {
    const service = interpret(authenticationMachine, {
      devTools: useInspector(),
    });
    service.start();
    return service;
  }
  const service = useInterpret(authenticationMachine, {
    devTools: useInspector(),
  });
  return service;
}

let serviceInstance: AuthService | null = null;

export function getAuthService() {
  if (!serviceInstance) {
    if (import.meta.env.MODE === 'test') {
      const machineWithConfig = authenticationMachine.withConfig(
        {},
        authenticationMachine.context || {}
      );
      serviceInstance = interpret(machineWithConfig, {
        devTools: useInspector(),
      }).start();
    } else {
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      serviceInstance = useInterpret(authenticationMachine, {
        devTools: useInspector(),
      });
    }
  }

  return serviceInstance;
}

export function useAuthService() {
  return getAuthService();
}

export function provideAuthService() {
  const service = getAuthService();
  // You dont necessarily have to use the provide/inject API, but I usually do since it makes it easier to pass the `appMachine` around the app and easier to test than mocking file imports.
  provide(authSymbol, service);

  return service;
}
