<template>
  <div>
    <!-- error messages are likely to be longer, hence multiline is applied -->
    <!-- visibility is controlled by the parent component, so timemout="-1" -->
    <v-snackbar
      :content-class="contentClass"
      :color="alertColour"
      :multi-line="isError"
      location="top"
      :timeout="-1"
      :model-value="active"
      class="notification-snackbar"
    >
      <!-- display the exclamation mark icon if the notification is an error message -->
      <v-icon v-if="isError">mdi-information-outline</v-icon>
      {{ message }}
      <template #actions="">
        <v-btn
          v-if="showCloseBtn"
          color=""
          variant="text"
          @click="dismissCurrentNotification"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
/**
 * The Notification Snackbar
 * @displayName Notification Snackbar
 *
 * Displays a Snackbar with a notification message.
 * If the notification has the level 'error', the style of the snackbar is altered.
 */
import { AlertLevel } from '@/common/types';
import { ALERT_LEVEL_INFO } from '@/constants';
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'NotificationSnackbar',
  props: {
    /**
     * The notification message to show
     */
    message: { type: String, required: true },
    /**
     * The severity of the notification
     */
    level: { type: String as () => AlertLevel, default: null },
    /**
     * Whether the snackbar should show
     */
    active: Boolean,
    /**
     * Whether non-error messages should be auto-dismissed
     */
    autoDismiss: Boolean,
  },
  setup(props, context) {
    const dismissHandler = () => {
      context.emit('dismiss-notification');
    };
    /**
     * Determine the colour applied to the v-snackbar
     */
    const alertColour = computed(() => {
      return props.level || ALERT_LEVEL_INFO;
    });
    /**
     * Dismiss the current notification
     */
    const dismissCurrentNotification = () => {
      if (props.active) {
        dismissHandler();
      }
    };
    /**
     * Determine whether the notification is an error message
     */
    const isError = computed(() => props.level === 'error');
    /**
     * Determine the class to be applied to the content of the v-snackbar
     * Non-error messages should be text-aligned to the center
     */
    const contentClass = computed(() => {
      return `notification-snackbar-content ${
        isError.value ? 'text-left' : 'text-center'
      }`;
    });
    /**
     * Determine whether the 'Close' button should be displayed.
     * If the notification is an error message, always display the button.
     * If the notification is not an error, but autoDismiss is not enabled, show the button.
     */
    const showCloseBtn = computed(() => {
      return isError.value || !props.autoDismiss;
    });
    return {
      alertColour,
      contentClass,
      isError,
      dismissHandler,
      dismissCurrentNotification,
      showCloseBtn,
    };
  },
});
</script>
