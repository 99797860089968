<template>
  <div>
    <AppLink class="text-uppercase text-decoration-none" :to="url"
      >{{ $t('forgotten_your_password') }}</AppLink
    >
  </div>
</template>

<script lang="ts">
import { env } from '@/env';
import { defineComponent } from 'vue';
import AppLink from '@/components/AppLink.vue';

/**
 * Renders a password reset link
 */
export default defineComponent({
  name: 'ForgotYourPasswordLink',
  components: {
    AppLink
  },
  data() {
    return {
      /**
       * The url of the password reset page
       */
      url: env().FORGOT_PASSWORD_LINK,
    };
  },
});
</script>

<style lang="sass">
//
</style>
