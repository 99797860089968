import { wrapWithJatsTags } from '@/itemTree/mapping/Util';

export interface AbstractData {
  abstract?: string;
  language?: string;
}

export class Abstract {
  readonly value: string | null;
  readonly language: string | null;

  constructor(value: string | null = null, language: string | null = null) {
    this.value = value;
    this.language = language;
  }

  /**
   * Factory method to create a new Abstract instance with the value wrapped in JATS tags if necessary.
   * @param value - The abstract text.
   * @param language - The language of the abstract.
   * @returns A new instance of Abstract with the value properly wrapped.
   */
  static create(
    value: string | null,
    language: string | null,
    wrapInJatsP: boolean = true
  ): Abstract | null {
    if (!value || !value.trim()) {
      return null;
    }
    const wrappedValue = wrapInJatsP ? wrapWithJatsTags(value) : value;
    return new Abstract(wrappedValue, language);
  }
}
