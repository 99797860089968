export enum RelationshipType {
  ResourceResolution = 'resource-resolution',
  License = 'license',
  Author = 'author',
  Chair = 'chair',
  Editor = 'editor',
  Translator = 'translator',
  Contributor = 'contributor',
  Affiliation = 'affiliation',
  Object = 'object',
  Funder = 'funder',
  Awarded = 'awarded',
  Issn = 'issn',
  Ancestor = 'ancestor',
}

export const mapContributorRoleToRelType = (
  role?: string | null
): RelationshipType => {
  switch (role) {
    case 'first-author':
      return RelationshipType.Author;
    case 'additional-author':
      return RelationshipType.Author;
    case 'chair':
      return RelationshipType.Chair;
    default:
      return RelationshipType.Contributor;
  }
};
