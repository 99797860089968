export const schema = {
  type: 'object',
  properties: {
    formData: { type: 'object' },
    fileName: { type: 'string' },
    recordType: { type: 'string' },
    recordName: { type: 'string' },
    recordExtension: { type: 'string' },
    schemaName: { type: 'string' },
    schemaVersion: { type: 'string' },
  },
  required: [
    'formData',
    'fileName',
    'recordType',
    'recordName',
    'recordExtension',
    'schemaName',
    'schemaVersion',
  ],
  additionalProperties: false,
};
