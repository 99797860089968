import { AuthenticatorLoggedInUser } from '@/composable/useAuth';
import { defineStore } from 'pinia';
import jwt from 'jsonwebtoken';
import { jwtDecode } from 'jwt-decode';

/**
 * Parses a JWT token and returns its payload as a JSON object.
 * @param token JWT token string.
 * @returns Parsed payload as an object, or null if parsing fails.
 */
function parseJwt(token: string): any {
  try {
    return jwtDecode(token); // Using jsonwebtoken's decode function
  } catch (error) {
    console.error('Failed to parse JWT token', error);
    return null;
  }
}

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    user: null as AuthenticatorLoggedInUser | null,
  }),
  actions: {
    /**
     * Logs out the user by clearing the user object from the store.
     */
    logoutUser() {
      this.user = null;
    },

    /**
     * Checks if the provided JWT token is valid, considering a safety margin.
     * @param token JWT token to validate.
     * @param safetyMarginSeconds Seconds before expiry to consider the token invalid (default is 30 seconds).
     * @returns True if token is valid, false otherwise.
     */
    isTokenValid(
      token: string,
      safetyMarginSeconds: number = 30,
      forceInvalid = false
    ): boolean {
      if (forceInvalid) return false;
      const payload = parseJwt(token);
      if (!payload || typeof payload.exp !== 'number') {
        return false;
      }
      const expiryTime = payload.exp * 1000; // Convert expiry time to milliseconds
      const currentTime = Date.now();
      const safetyMargin = safetyMarginSeconds * 1000;
      return currentTime + safetyMargin < expiryTime;
    },

    /**
     * Retrieves the user if the token is valid; otherwise, logs out the user.
     * @returns AuthenticatorLoggedInUser object if valid, null otherwise.
     */
    getUserIfValid(): AuthenticatorLoggedInUser | null {
      if (this.user && this.isTokenValid(this.user.token)) {
        return this.user;
      } else {
        this.logoutUser();
        return null;
      }
    },

    /**
     * Retrieves the token if it's valid; otherwise, returns null.
     * @returns JWT token string if valid, null otherwise.
     */
    getValidToken(): string | null {
      const user = this.getUserIfValid();
      return user ? user.token : null;
    },
  },
  persist: {
    storage: localStorage,
  },
});
