// src/stores/dialogs.ts
import {defineStore} from 'pinia';
import { URL_DOCS_ROOT } from '@/constants/urls';
import { AppService } from '@/statemachines/app.machine';

export interface Store {
  appService: AppService | undefined;
  getHelpUrl: string;
}

export const useAppStore = defineStore('app', {
  state: (): Store => ({
    getHelpUrl: URL_DOCS_ROOT,
    appService: undefined,
  }),
  actions: {
    resetGetHelpUrl() {
      this.getHelpUrl = URL_DOCS_ROOT;
    },
    setAppService(service: AppService) {
      this.appService = service;
    },
  },
});
