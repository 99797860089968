<template>
  <v-btn
    block
    type="submit"
    color="primary"
    :disabled="disabled"
    data-cy="login-button"
  >
    <!-- @slot Set the text on the button -->
    <slot>Login</slot>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * Renders the 'login' button
 */
export default defineComponent({
  name: 'LoginBoxActionButtonLogin',
  props: {
    /**
     * Whether the login button is clickable
     */
    disabled: {
      type: Boolean,
    },
  },
});
</script>

<style scoped>

</style>
