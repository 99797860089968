<template>
  <v-container>
    <v-row wrap>
      <v-col cols="12">
        <v-card height="400" color="transparent" flat>
          <div class="text-h2 mt-5">404 Error</div>
          <div class="text-h3 mt-4 text-grey lighten-5">
            Sorry about this...
          </div>
          <div class="paragraph-text mt-2">
            <br />
            <br />This page doesn't exist. If you followed a link to get here,
            please
            <a href="mailto:feedback@crossref.org">report it to us by email</a>
            and we'll correct it.
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'PageNotFound',
});
</script>

<style>
.paragraph-text {
  font-size: 18px;
}
</style>
