<template>
  <v-card>
    <v-card-title>Not Authorised</v-card-title>
    <v-card-text>
      <p>You are not authorised to access this page.</p>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'NotAuthorised',
});
</script>
