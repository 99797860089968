<template>
  <v-breadcrumbs v-if="hasBreadcrumbs" :items="breadcrumbs"></v-breadcrumbs>
</template>

<script lang="ts">
import { resolveBreadcrumbs } from '@/utils/routes';
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'Breadcrumbs',
  setup() {
    const route = useRoute();
    const breadcrumbs = computed(() =>
      resolveBreadcrumbs(route.matched, route).filter(
        (item) => item.hide !== true
      )
    );
    const hasBreadcrumbs = computed(() => breadcrumbs && breadcrumbs.value?.length > 0)

    return {
      breadcrumbs,
      hasBreadcrumbs,
    };
  },
});
</script>

<style scoped>
/* Add any custom styles here */
</style>
