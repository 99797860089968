<!-- eslint-disable vue/no-v-html -->
<template>
  <v-alert
    v-show="errorMessage"
    class="alert-error"
    type="error"
    data-cy="alert-error"
    ><span v-html="errorMessage"></span
  ></v-alert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * A Vuetify alert set to type "error"
 * Error message is shown as unescaped HTML to allow html responses from the auth API
 */
export default defineComponent({
  name: 'AlertError',
  props: {
    /**
     * The message to display
     */
    errorMessage: {
      type: String,
      default: null,
    },
  },
});
</script>

<style>
/**
Underline links in html error messages
 */
.alert-error .v-alert__content a {
  color: white;
  text-decoration: underline;
}
</style>
