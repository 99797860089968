<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { AppError } from '@/errors';
import { useTranslationNamespace } from '@/composable/useI18n';
import { I18nNamespace } from '@/i18n';

const props = defineProps<{
  error: AppError | null;
}>();

const t = useTranslationNamespace(I18nNamespace.App);

const copied = ref(false); // Reactive state to track the copy status

// Reactive reference for dynamic max height based on viewport size
const maxCardHeight = ref(`${window.innerHeight * 0.4}px`);

// Update maxCardHeight when window size changes
const updateMaxHeight = () => {
  maxCardHeight.value = `${window.innerHeight * 0.4}px`;
};

onMounted(() => {
  window.addEventListener('resize', updateMaxHeight);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateMaxHeight);
});

// Function to copy error information
const copyErrorInfo = async () => {
  if (copied.value) return;
  if (!props.error) return;
  let errorInfo = '';
  if (props.error.code) {
    errorInfo += `Error Code: ${props.error.code}\n`;
  }
  if (props.error.message) {
    errorInfo += `Error: ${props.error.message}\n`;
  }
  if (props.error.domainMessage) {
    errorInfo += `Details: ${props.error.domainMessage}\n`;
  }
  try {
    await navigator.clipboard.writeText(errorInfo.trim());
    copied.value = true; // Set copied to true on successful copy

    setTimeout(() => {
      copied.value = false; // Revert back to the original button after 2 seconds
    }, 2000);
  } catch (error) {
    console.error('Failed to copy text: ', error);
  }
};
</script>


<template>
  <div v-if="props.error">
    <div class="button-container text-end">
      <v-btn variant="flat" small @click="copyErrorInfo">
        <v-icon class="pe-2">{{ copied ? 'mdi-check' : 'mdi-content-copy' }}</v-icon>
        {{ copied ? t('status-copied') : t('action-copy') }}
      </v-btn>
    </div>
    <v-card :max-height="maxCardHeight" class="overflow-y-auto">
      <div class="error-message">
      <div v-if="props.error.code" class="error-code">Error Code: {{ props.error.code }}</div>
      <div v-if="props.error.message" class="error-text">Error: {{ props.error.message }}</div>
      <div v-if="props.error.domainMessage" class="domain-message">Details: {{ props.error.domainMessage }}</div>
    </div>
    </v-card>
  </div>
</template>

<style scoped>
.v-card {
  scrollbar-color: #b0b0b0 #202020; /* for Firefox */
  scrollbar-width: thin; /* for Firefox */
}

.v-card::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.v-card::-webkit-scrollbar-track {
  background: #202020; /* color of the tracking area */
}

.v-card::-webkit-scrollbar-thumb {
  background-color: #b0b0b0; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #343a40; /* creates padding around scroll thumb */
}

.error-message {
  padding: 12px;
  background-color: #343a40;
  border: 1px solid #495057;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre-wrap;
  color: #f8f9fa;
  overflow-x: auto;
}

.error-code,
.error-text,
.domain-message {
  margin-bottom: 8px;
}
</style>
