import { store } from '@/store';

const sendAlert = (message: string, level: string) => {
  // @ts-ignore typing should improve with Xstate5
  store.appService.state.children['notificationsMachine'].send({
    type: 'ALERT',
    msg: message,
    alert: { msg: message, level: level },
  });
};

export const useAlert = () => {
  const alertError = (message: string) => {
    sendAlert(message, 'error');
  };

  const alertSuccess = (message: string) => {
    sendAlert(message, 'success');
  };

  const alertWarning = (message: string) => {
    sendAlert(message, 'warning');
  };

  const alertInfo = (message: string) => {
    sendAlert(message, 'info');
  };

  return {
    alertError,
    alertWarning,
    alertInfo,
    alertSuccess,
  };
};
