<script setup lang="ts">
import { useTranslationNamespace } from '@/composable/useI18n';
import { I18nNamespace } from '@/i18n';
import { FormDataService } from '@/statemachines/form-data.machine';

const _props = defineProps<{
  formService: FormDataService
}>();

const t = useTranslationNamespace(I18nNamespace.ContentRegistration);
const doDownload = () =>_props.formService.send('DOWNLOAD_RECORD')

</script>

<template>
  <v-btn
    v-if="formService"
    icon
    variant="text"
    color="primary"
    :title="t('action-download-record')"
    :aria-label="t('action-download-record')"
    @click="doDownload"
  >
  <v-icon icon="mdi-cloud-download-outline" size="x-large"></v-icon></v-btn>
</template>
