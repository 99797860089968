import { ComponentNames } from './componentNames';

const componentMap = {
  [ComponentNames.ToolbarIcon]: () => import('@/components/ToolbarIcon.vue'),
  [ComponentNames.ToolbarItemDownloadRecord]: () =>
    import('@/components/ToolbarItemDownloadRecord.vue'),
  [ComponentNames.ToolbarItemRecordRegistrationHelp]: () =>
    import('@/components/ToolbarItemRecordRegistrationHelp.vue'),
};

export default componentMap;
