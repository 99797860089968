<template>
  <!-- v-dialog: Vuetify component for creating dialogs -->
  <v-dialog
    v-model="dialog"
    :width="width"
    content-class="global-dialog"
    :persistent="persistent"
    @keydown.esc="cancel"
  >
    <component
      :is="dynamicComponent"
      v-if="componentTakesOver"
      v-bind="componentProps"
      @cancel="cancel"
      @confirm="confirm"
    ></component>
    <v-card v-else>
      <!-- v-card-title: Vuetify component for card titles -->
      <v-card-title>{{ t(title) }}</v-card-title>
      <v-card-subtitle>{{ t(subTitle) }}</v-card-subtitle>

      <!-- Display the dynamic component if the type is 'component' and a dynamicComponent is provided -->
      <v-card-text v-if="type === 'component' && dynamicComponent">
        <component
          :is="dynamicComponent"
          v-bind="componentProps"
          @cancel="cancel"
          @confirm="confirm"
        ></component>
      </v-card-text>

      <!-- Display the message if the type is 'confirm' -->
      <v-card-text v-if="type === 'confirm'">{{ message }}</v-card-text>

      <!-- Display the input field if the type is 'input' -->
      <v-card-text v-if="type === 'input'">
        <!-- Display a warning message if provided -->
        <p
          v-if="warningMessage"
          class="text-red font-weight-medium"
          type="error"
        >
          {{ t(warningMessage) }}
        </p>
        <!-- Display the text input field -->
        <v-text-field
          v-model="textValue"
          :label="message"
          variant="underlined"
          class="global-dialog__text-input"
          autofocus
        ></v-text-field>
      </v-card-text>

      <!-- Display the custom actions component if provided -->
      <component
        :is="dynamicActionsComponent"
        v-if="dynamicActionsComponent"
        :disable-confirm="disableConfirm"
        @cancel="cancel"
        @confirm="confirm"
      ></component>

      <!-- Display the default actions if no custom actions component is provided -->
      <v-card-actions v-else-if="!hideDefaultActions">
        <!-- Cancel button -->
        <v-btn
          v-if="showCancelButton"
          variant="text"
          color=" "
          class="button--cancel global-dialog__cancel-button"
          @click="cancel"
          >{{ t(cancelButtonText) }}</v-btn
        >
        <v-spacer></v-spacer>
        <!-- Confirm button -->
        <v-btn
          :disabled="disableConfirm"
          color="info"
          class="button--accept global-dialog__confirm-button"
          @click="confirm"
          >{{ t(confirmButtonText) }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Ref, defineComponent, ref, watch } from 'vue';
import {
  dialog,
  type,
  title,
  subTitle,
  message,
  textValue,
  confirm,
  cancel,
  disableConfirm,
  confirmButtonText,
  cancelButtonText,
  showCancelButton,
  inputType,
  warningMessage,
  component, // import component ref
  componentProps, // import componentProps ref
  componentOnly,
  actionsComponent,
  hideDefaultActions,
  width,
  persistent,
} from '@/composable/useGlobalDialog';
import { useTranslation } from '@/composable/useI18n';

export default defineComponent({
  setup() {
    const t = useTranslation();

    /**
     * Watch the value of the dialog, to catch closures triggered by clicks outside the dialog (on the scrim)
     */
    watch(dialog, (newVal) => {
      if (!newVal) {
        cancel();
      }
    });

    // Create a local ref for the dynamic component
    // This local reference is necessary to establish a reactive relationship
    // between the global composable and this component's template.
    const dynamicComponent = ref<Ref<any> | null>(null);

    // Watch the component ref from the composable for changes
    // When the component value changes, update the local dynamicComponent ref
    // This ensures that the dynamic component in the template is updated correctly
    watch(component, (newVal) => {
      if (newVal) {
        dynamicComponent.value = newVal;
      } else {
        dynamicComponent.value = null;
      }
    });

    // Create a local ref for the dynamic actions component
    const dynamicActionsComponent = ref<Ref<any> | null>(null);

    // Watch the actionsComponent ref from the composable for changes
    watch(actionsComponent, (newVal) => {
      if (newVal) {
        dynamicActionsComponent.value = newVal;
      } else {
        dynamicActionsComponent.value = null;
      }
    });

    return {
      t, // translation function
      dialog, // dialog state (open/closed)
      type, // type of dialog (confirm/input/component)
      title, // title of the dialog
      subTitle, // subtitle of the dialog
      message, // message to display in the dialog
      textValue, // input value for input type dialog
      confirm, // function to confirm the dialog
      cancel, // function to cancel the dialog
      disableConfirm, // computed property to disable the confirm button
      confirmButtonText, // i18n message key for the confirm button
      cancelButtonText, // i18n message key for the cancel button
      showCancelButton, // show or hide the cancel button
      inputType, // input type for input type dialog (free/confirmation)
      warningMessage, // warning message for confirmation input type

      dynamicComponent, // dynamic component to be rendered in the dialog
      componentProps, // props for the dynamic component
      componentTakesOver: componentOnly,
      dynamicActionsComponent, // dynamic actions component for custom actions
      hideDefaultActions, // whether to show the default action buttons if there is no custom actions component

      width, // width of the dialog
      persistent, // prevent closure on pressing ESC or clicking outside the modal
    };
  },
});
</script>
